import React, { useEffect, useState } from "react";
import { Link,useHistory,useParams } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
const querystring = require("querystring");

const LayoutDetails = () => {
  const {id} = useParams();
	const history = useHistory()
  const [targetTypes, setTargetTypes] = useState([]);
  const [message,setMessage] = useState("")
  const [recipe, setRecipe] = useState({
    portalRecipeId: id,
    portalRecipeName: "",
    portalRecipeDescription: "",
    securityContextId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    userProjectId: 0,
    userWorkspaceId: 0,
    targetTypeCode: 0,
    portalAccessServer: "",
    portalAccessPath: "",
    portalAccessProtocol: "",
    portalAccessUsername: "",
    portalAccessPassword: "",
    buildStatusCode: 0,
    transformationId: 0,
  });
  const [state, setState] = useState({
    layoutDetailsObject: {
      projectId: 0,
      portalRecipeDescription: " ",
      targetTypeCode: 0,
    },
    layoutDetails: [],
    projects: [],
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    await axios
    .get(
      `${globalConfig.apiDatahubUrl}/portal-recipes/${recipe.portalRecipeId}`,
      globalConfig.hardcoded_header
    )
    .then((res) => {
      const recipe = res.data;

      //console.log("res", recipe);
      setRecipe(recipe);
    });

    const { data: dataAppTypes } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/APPLICATION_TYPES`,
      globalConfig.hardcoded_header
    );
    setTargetTypes(dataAppTypes);

    await axios
      .get(
        `${globalConfig.apiMainUrl}/user-projects-by-id/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const projects = res.data;
        setState({ projects });
        //console.log("project is", projects);
      });

  
  };

  const handleSave = () => {
    /* 	recipe.targetTypeCode =  Number(recipe.targetTypeCode); 
		recipe.buildStatusCode = Number(recipe.buildStatusCode); 
		recipe.transformationId = Number(recipe.transformationId); */

    var url = `${globalConfig.apiDatahubUrl}/portal-recipes/${id}`;
    axios
      .patch(url, recipe, globalConfig.hardcoded_header)
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
          window.location.reload();
        } else {
          setMessage("Failed");
        }
        console.log(message);
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    console.log("message info - " + message);

    //handleClear();
  };

  const layoutDetailsObject = state.layoutDetailsObject;

  return (
    <form>
		<div style={{display:"flex",justifyContent:"flex-end"}}>
		<div style={{cursor:"pointer",margin:"0px 5px"}} onClick={()=>history.goBack()} className="btn btn-primary">Go Back</div>
		</div>
		<hr/>
      <h4>Layout Details</h4>
      <br/>
      <br/>
      <div className="container-fluid">

        <div className="form-group row">
		
          <label className="ContentTitle col-sm-2" value="Recipe Name">
            Recipe Name
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="portalRecipeName"
            onChange={(e)=>setRecipe({...recipe,portalRecipeName:e.target.value})}
            value={recipe.portalRecipeName}
          ></input>
         {/*  <label
            className="ContentTitle col-sm-2 text-danger"
            rendered="#{ombPortalLayoutForm.currentPortalRecipe._portal_recipe_id eq 0}"
            value=" Please Note! This recipe has not been saved."
          >
            Please Note! This recipe has not been saved
          </label> */}
        </div>

        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Project">
            Select Project
          </label>
          <select
            id="projectId"
            className="form-control input-big col-sm-8"
            value={recipe.userProjectId}
            onChange={(e)=>setRecipe({...recipe,userProjectId:e.target.value})}
          >
            <option>Select Project</option>
            {state.projects.length > 0 &&
              state.projects?.map((project) => (
                <option
                  key={project.userProjectId}
                  value={project.userProjectId}
                >
                  {project.projectName}
                </option>
              ))}
            {/*<option value={ombUserProjectsForm.getUserProjectList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label value="Project Target Type" className="ContentTitle col-sm-2">
            Project Target Type
          </label>
          <select
            id="targetTypeCode"
            value={state.layoutDetailsObject?.targetTypeCode}
            className="form-control input-xlarge col-sm-8"
            value={recipe.targetTypeCode}
            onChange={(e)=>setRecipe({...recipe,targetTypeCode:parseInt(e.target.value)})}
          >
            {targetTypes &&
              targetTypes.map((targetType) => (
                <option
                  key={targetType.systemCodeValueId}
                  value={targetType.systemCodeValueId}
                >
                  {targetType.systemCodeValueValue}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group row">
          <label
            value="Target Portal Description"
            className="ContentTitle col-sm-2"
          >
            Target Portal Description
          </label>
          <input
            id="portalRecipeDescription"
            onChange={(e) => 
              setRecipe({...recipe,portalRecipeDescription:e.target.value})
            }
            cols="100"
            rows="1"
            className="form-control input-xlarge col-sm-8"
            value={recipe.portalRecipeDescription}
          />
        </div>
        <hr />

		<div style={{display:"flex",width:"100%",justifyContent:"flex-end"}}>
					<div style={{padding:"0px !important",display:"flex",justifyContent:"space-between",width:"50%",paddingLeft:"10px"}}>
        <div
        onClick={handleSave}
			style={{cursor:"pointer"}}
          className="btn btn-success"
          value="Save The Layout Configuration As A Recipe"
          actionListener="#{ombPortalLayoutForm.saveLayoutRecipe}"
        >
          Save The Layout Configuration As A Recipe
        </div>
      {/*   <div
			style={{cursor:"pointer"}}
          className="btn btn-primary"
          value="Clear"
          actionListener="#{ombPortalLayoutForm.clear}"
        >
          Clear
        </div> */}
        <div
			style={{cursor:"pointer"}}
          className="btn btn-danger"
          value="Delete"
          actionListener="#{ombPortalLayoutForm.delete}"
        >
          Delete
        </div>
        <div
			style={{cursor:"pointer"}}
          className="btn btn-warning"
          value="Next Step >> "
          onClick={()=>history.replace(`/BuildDetails/${recipe.portalRecipeId}`)}
        >
          Next Step
        </div>
		</div>
		</div>
      </div>
	  
    </form>
  );
};

export default LayoutDetails;
