import React, { useState, useEffect ,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link, useParams, useHistory } from "react-router-dom";
import { IconButton, InputAdornment } from '@material-ui/core'
import axios from "axios";
import { globalConfig } from "../config";
// import { SelectListComponent } from 'shared-components';
import { FaCalendar } from 'react-icons/fa'
import DateFnsUtils from '@date-io/date-fns'
import { Controller, useForm } from 'react-hook-form'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
const RecipeStepDetail = () => {
  const data = useContext(AuthContext)
  const history = useHistory();
  const { id, rid } = useParams();
  const [statusCodes, setStatusCodes] = useState([]);
  const [priorityCodes, setPriorityCodes] = useState([]);
  const [username,setUsername] = useState("");
  const [lastUpdateUsername,setLastUpdatedUsername] = useState("")
  const [message, setMessage] = useState("");
  const [recipeSteps, setRecipeSteps] = useState([]);
  const [transformations, setTransformations] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [recipeStep, setRecipeStep] = useState({
    recipeStepId: 0,
    recipeId: 0,
    recipeSeqPos: 0,
    recipeStepName: "",
    securityContextId: 0,
    statusCode: 0,
    priorityCode: 0,
    instructions: "",
    recipeStepDescription: "",
    prevStepId: 0,
    nextStepId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    transformerId: 0,
    triggerId: 0,
  });
  const [state, setState] = useState({
    currentRecipeStepId: id,
    currentRecipeId: rid,
    currentRecipeName: "",
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    getLastUser(recipeStep.lastUpdateUser)
 }, [recipeStep.lastUpdateUser]);

 const getLastUser =async(uid)=>{
   axios
   .get(
     `${globalConfig.apiDatahubUrl}/users/${uid}`,
     globalConfig.hardcoded_header
   )
   .then((res) => {
     const user = res.data;
     console.log("user", user);
     setLastUpdatedUsername(user.username);
   });

 }

  useEffect(() => {
     getUser(recipeStep.createUser)
  }, [recipeStep.createUser]);

  const getUser =async(uid)=>{
    axios
    .get(
      `${globalConfig.apiDatahubUrl}/users/${uid}`,
      globalConfig.hardcoded_header
    )
    .then((res) => {
      const user = res.data;
      console.log("user", user);
      setUsername(user.username);
      
    });

  }


  const handleOnLoad = async () => {
    //axios.get(`http://os-dev.origosoftware.com:3000/portal-recipes/`).then(res => {
    //const recipes = res.data;
    //console.log(recipes);
    //setState({ recipes : recipes });
    //});

    await axios
      .get(
        `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/TASK_STATUS/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const statusCodes = res.data;
        console.log("status", statusCodes);
        setStatusCodes(statusCodes);
      });

    await axios
      .get(
        `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/TASK_PRIORITY/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const priorityCodes = res.data;
        console.log("status", priorityCodes);
        setPriorityCodes(priorityCodes);
      });

    console.log("getting step - " + state.currentRecipeStepId);
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/recipe-steps/${state.currentRecipeStepId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipeStep = res.data;
        console.log("steps", recipeStep);
        setRecipeStep(recipeStep);
      });

    console.log("getting steps for recipeid - " + state.currentRecipeId);
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/recipe-steps?filter[where][recipeId]=${state.currentRecipeId}/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipeSteps = res.data;
        console.log(recipeSteps);
        setRecipeSteps(recipeSteps);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformations = res.data;
        setTransformations(transformations);
      });
      await axios
      .get(
        `${globalConfig.apiMainUrl}/event-triggers/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const triggers = res.data;
        setTriggers(triggers);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/${state.currentRecipeId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipe = res.data;
        setState({ currentRecipeName: recipe.portalRecipeName });
      });
  };
  const handleDelete = async () => {
    console.log("id", id);
    console.log("deleting step - " + id);
    await axios
      .delete(
        `${globalConfig.apiDatahubUrl}/recipe-steps/${id}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        if (res.status === 200) {
          history.push("/RecipeStepSearch");
        } else {
          alert("Failed to delete");
        }
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    //window.location.href = "/RecipeStepSearch";
  };
  const handleSave = async () => {
    console.log("handling the save event");

    console.log(recipeStep);
    //setState({recipeStep : {...state.recipeStep, transformerId : Number(recipeStep.transformerId)}});
    recipeStep.transformerId = Number(recipeStep.transformerId);
    recipeStep.recipeSeqPos = Number(recipeStep.recipeSeqPos);
    recipeStep.triggerId = "";
    await axios
      .patch(
        `${globalConfig.apiDatahubUrl}/recipe-steps/${recipeStep.recipeStepId}`,
        recipeStep,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const results = res.data;
        console.log(results);

        console.log("response.data");
        if (res.status === 200) {
          window.location.reload();
        } else {
          alert("Something went wrong");
        }
        console.log(message);
      })
      .catch((err) => {
        console.log("error - " + err);
      });
  };
  const handleClear = () => {
    setRecipeStep({
      recipeStepId: 0,
      recipeId: 0,
      recipeSeqPos: 0,
      recipeStepName: "",
      securityContextId: 0,
      statusCode: 0,
      priorityCode: 0,
      instructions: "",
      recipeStepDescription: "",
      prevStepId: 0,
      nextStepId: 0,
      createUser: 0,
      createDate: new Date(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      transformerId: 0,
      triggerId: "",
    });
    setRecipeSteps([]);
    setTransformations([]);
    setTriggers([]);
    setState({
      currentRecipeStepId: 0,
      currentRecipeId: 0,
      currentRecipeName: "",
    });
  };
  const handleChange = (event) => {
    //setState({recipe : {...state.recipe, targetTypeCode : Number(state.recipe.targetTypeCode)}});

    console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setRecipeStep({
      ...recipeStep,
      [target.id]: target.value,
    });
    //console.log(state);
  };

  return (
    <form>
      	<div style={{display:"flex",justifyContent:"flex-end"}}>
		<div style={{cursor:"pointer",margin:"0px 5px"}} onClick={()=>history.goBack()} className="btn btn-primary">Go Back</div>
		</div>
      <h4>Manage Recipe Step</h4>
      <hr />

      <div className="container-fluid">
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe">
            Recipe
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="recipeName"
            value={state.currentRecipeName}
          />

          {/*
		            	<select className="form-control input-xlarge col-sm-8" id="recipeId" value={state.selectedRecipeId} id="recipeId" onChange={handleChange}>
					{state.recipes.map((recipe) => <option key={recipe.portalRecipeId} value={recipe.portalRecipeId}>{recipe.portalRecipeName}</option>)}
			        </select>
				<select id="recipeId" value={recipeStep.recipeId} className="form-control input-xlarge col-sm-8">
				<option value={ombRecipeStepForm.recipeSelectList} />
				</select>
			*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Seq Pos">
            Recipe Seq Pos
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="recipeSeqPos"
            value={recipeStep.recipeSeqPos}
            onChange={handleChange}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Name">
            Name{" "}
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="recipeStepName"
            value={recipeStep.recipeStepName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2">Status Code</label>
          <select
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
            id="statusCode"
            value={recipeStep.statusCode}
          >
            {statusCodes &&
              statusCodes.map((status) => (
                <option
                  key={status.systemCodeValueId}
                  value={status.systemCodeValueId}
                >
                  {status.systemCodeValueValue}
                </option>
              ))}
          </select>

          {/*
			<SelectListComponent IdName="statusCode" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"90"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
			<select id="statusCode" value={recipeStep.statusCode} className="form-control input-xlarge col-sm-8">
			<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TASK_STATUS')} />
			</select>
	*/}
        </div>
        <div className="form-group row">
          <label className="CContentTitle col-sm-2" value="Priority Code">
            Priority Code
          </label>
          <select
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
            id="PriorityCode"
            value={recipeStep.priorityCode}
          >
            {priorityCodes &&
              priorityCodes.map((priority) => (
                <option
                  key={priority.systemCodeValueId}
                  value={priority.systemCodeValueId}
                >
                  {priority.systemCodeValueValue}
                </option>
              ))}
          </select>
          {/*
			<SelectListComponent IdName="priorityCode" onChange={handleChange} entityName ={"system-code-values"} 
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"91"} displayColumnName ={"systemCodeValueValue"}  onChange={handleChange}
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
			<select id="priorityCode" value={recipeStep.priorityCode} className="form-control input-xlarge col-sm-8">
			<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TASK_PRIORITY')} />
			</select>
	*/}
        </div>
        <div className="form-group row">
          <label className="ContentTi col-sm-2" value="Instructions">
            Instructions
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="instructions"
            value={recipeStep.instructions}
            onChange={handleChange}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Description">
            Description
          </label>
          <input
            className="form-control input-xlarge col-sm-8"
            id="recipeStepDescription"
            value={recipeStep.recipeStepDescription}
            onChange={handleChange}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Trigger">
            Trigger
          </label>
          <select
            id="triggerId"
            value={recipeStep.triggerId}
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
          >
            {triggers &&
              triggers.map((trigger) => (
                <option
                  key={trigger.transformationId}
                  value={trigger.transformationId}
                >
                  {trigger.transformationName}
                </option>
              ))}
                    
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Transformer">
            {" "}
            Transformer
          </label>
          <select
            id="transformerId"
            value={recipeStep.transformerId}
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
          >
            {transformations &&
              transformations.map((transformation) => (
                <option
                  key={transformation.transformationId}
                  value={transformation.transformationId}
                >
                  {transformation.transformationName}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group row">
          <label value="Run Time" className="ContentTitle col-sm-2">
            Run Time
          </label>
          <input
            className="form-control input-xlarge col-sm-2"
            id="stepRunLatency"
          />
          <select
            id="task_frequency"
            name="stepRunfrequency"
            className="form-control col-sm-6"
            onChange={handleChange}
          >
            <option value="immediately">Immediately</option>
            <option value="seconds">Seconds</option>
            <option value="hourly">Hours</option>
            <option value="daily">Days</option>
            <option value="weekly">Weeks</option>
            <option value="monthly">Months</option>
            <option value="quarterly">Quarters</option>
            <option value="annually">Years</option>
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Prev Step">
            {" "}
            Prev Step{" "}
          </label>
          <select
            id="prevStepId"
            value={recipeStep.prevStepId}
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
          >
            {recipeSteps &&
              recipeSteps.map((recipeStep) => (
                <option
                  key={recipeStep.recipeStepId}
                  value={recipeStep.recipeStepId}
                >
                  {recipeStep.recipeStepName}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Next Step">
            {" "}
            Next Step{" "}
          </label>
          <select
            id="nextStepId"
            value={recipeStep.nextStepId}
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
          >
            {recipeSteps &&
              recipeSteps.map((recipeStep) => (
                <option
                  key={recipeStep.recipeStepId}
                  value={recipeStep.recipeStepId}
                >
                  {recipeStep.recipeStepName}
                </option>
              ))}
          </select>
        </div>
      </div>
      <hr />
      <div columns="2">
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Create User">
            {" "}
            Create User
          </label>
          <div columns="3" border="0">
            <input
              className="form-control input-medium"
              name="createUser"
              readOnly={true}
              value={username}
            ></input>
          </div>
          <div className="form-group row">
            <label style={{marginLeft:5}} className="ContentTitle col-sm-3" value=" On ">
              {" "}
              On{" "}
            </label>
          </div>
         
           <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(recipeStep.createDate)}
                    disabled
                   
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <FaCalendar size={14} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Last Update User">
            {" "}
            Last Update User
          </label>
          <div columns="3" border="0">
            <input
              className="form-control input-medium"
              name="lastUpdateUser"
              readOnly={true}
              value={lastUpdateUsername}
            ></input>
          </div>
          <div className="form-group row">
            <label style={{marginLeft:10}} className="ContentTitle col-sm-1" value=" On ">
              On
            </label>
          </div>
          {/* <input
            className="form-control col-sm-2"
            name="lastUpdateDate"
            readOnly={true}
            value={new Date(recipeStep.lastUpdateDate)}
            pattern="MM/dd/yyyy"
          ></input> */}
          <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(recipeStep.lastUpdateDate)}
                    disabled
                   
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <FaCalendar size={14} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
          
          
        </div>
       
                
        
      </div>
      <hr />

      <div className="form-group row" style={{margin:"0px 10px",display:"flex",justifyContent:"flex-end"}}>
       
      
        <div
          style={{ pointer: "cursor" }}
          className="btn btn-success"
          onClick={handleSave}
        >
          {" "}
          Save{" "}
        </div>
        &nbsp;
        <div
          style={{ pointer: "cursor" }}
          className="btn btn-danger"
          onClick={handleDelete}
        >
          {" "}
          Delete{" "}
        </div>
      </div>
    </form>
  );
};

export default RecipeStepDetail;
