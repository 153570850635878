import React, {Component} from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';
const querystring = require('querystring');

export default class Transformers extends Component {

	constructor(props)
	{
		console.log(props);
    super(props);
  }

  state = {
    transformations: []
  };

	componentDidMount() 
	{
		this.handleOnLoad();
	}

	handleOnLoad = () => {
      axios.get(`http://os-dev.origosoftware.com:3000/transformations/`).then(res => {
      const transformations = res.data;
      console.log(transformations);
      this.setState({ transformations });
      console.log(res.data[0]);
		});
		
	}
	
	render(){
    return (
		<form>
		<div class="container-fluid">
      <div className="text-left">
			  <h4>Transformations</h4>
      </div>
      <div className="form-group row">
      	<table class="table table-bordered table-striped">
      	<thead>
      	<tr>
      	<th scope="col">#</th>
      	<th scope="col">Name</th>
     	<th scope="col">Action</th>
    	</tr>
  		</thead>
  		<tbody>

          {this.state.transformations.map( ( {transformationName, transformationId} ) => {
  					  return <tr>
  					  	<td>{transformationId}</td>
  					  	<td>{transformationName} </td>
  					  	<td><button class="btn btn-danger">Delete</button></td>
  					  	</tr>
					}) 
				}
        </tbody>
        </table>
      </div>
      </div>
		</form>		
	  );
	}
}