import React, {Component} from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';

export default class ManageRecipes extends Component{
    constructor(props)
	{
		console.log(props);
		super(props);
		this.state = {}
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	/**
	 * General input event handler! 
	 * Reduces multiple inputs down to one function.
	 */
	handleChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value; // checkboxes work differently
		const name = target.name;
		this.setState({ [name] : value});
	}

	/**
	 * Handles what occurs during form submission. Calls the susbcription service
	 * Create Coupons routing method.
	*/
	handleSubmit(event) {
		console.log(this.state);
        axios.post('/subscriptionService/createVouchers', this.state)
			.then(response => {
				console.log(response.data);
				alert("Vouchers successfully created!");
				window.location.reload();
			})
			.catch(err => {
				console.log(err);
			})
		event.preventDefault();
    }
    render(){
        return(
            <form onSubmit={this.handleSubmit}>		
			<h1>Dynamo</h1>		
            {/* <hr /> */}
            <div className="form-group row">
                <p> Search</p>
            </div>
            <div className="form-group row">
                <p> Receipe Detail</p>
            </div>
            <div className="form-group row">
                <p> Create Receipe</p>
            </div>
		</form>
        );
    }
}