import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
const querystring = require("querystring");
const RecipeSearch = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    portalRecipes: [],
  });
  useEffect(() => {
    handleOnLoad();
  }, []);
  useEffect(() => {
    handleSearch(search);
  }, [search]);
  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const portalRecipes = res.data;
        setState({ portalRecipes });
      });
  };
  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/portal-recipes?`;
    URL += `&filter[where][or][0][portalRecipeName][regexp]=/(${search})/i`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const transformations = res.data;
      //console.log(transformations);
      setState({
        ...state,
        filteredTransformers: transformations,
      });
      //console.log(res.data[0]);
    });
  };

  return (
    <form>
      <div className="container-fluid">
	  <div className="text-right">
            <Link
              to="/triggerCreate"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="btn btn-success"
            >
              Create Recipe
            </Link>
          </div>
        <div className="form-group">
          <input
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
        <div className="form-group row">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Recipe Name</th>
                <th scope="col">Build Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {state.portalRecipes &&
                state.portalRecipes.map(
                  ({ portalRecipeId, portalRecipeName, buildStatusCode }) => {
                    return (
                      <tr>
                        <td>{portalRecipeId}</td>
                        <td>{portalRecipeName}</td>
                        <td>{buildStatusCode}</td>
                        <td>
                          <button class="btn btn-success">Edit</button>&nbsp;
                          <button class="btn btn-danger">Delete</button>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default RecipeSearch;
