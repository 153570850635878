import React, { createContext, useEffect, useState, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../Loading'
import { API_PATHS, instance, setToken } from '../../services/index'
import axios from 'axios'
import { globalConfig } from '../../config'

export const AuthContext = createContext({ userMetadata: null })

export const Auth = props => {

  	const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()
   	const [userMetadata, setUserMetaData] = useState(null)
 	const [hasActiveSubscription, setHasActiveSubscription] = useState(false)

	if (!isAuthenticated && !isLoading)
	{
		loginWithRedirect();
	}


  	const getUserMetadata = async () => {
	    try 
	    {
      		const baseURL = `${globalConfig.auth0.audience}`;
      		const userDetailsByIdUrl = `${baseURL}users/${user.sub}`;
		    const accessToken = await getAccessTokenSilently({
		    	authorizationParams: {
	        		audience: baseURL,
    	    		scope: 'read:current_user',
		    	},
      		});
			//console.log('accessToken - ' + accessToken);
		    const metadataResponse = await fetch(userDetailsByIdUrl, {
        		headers: {
          			Authorization: `Bearer ${accessToken}`,
        		},
      		});

      		const { user_metadata: userMeta } = await metadataResponse.json()

			//console.log('userMeta - ' + JSON.stringify(userMeta));
      
      		if (userMeta) {
        		setToken(`${userMeta.apiKey}:${userMeta.userId}`);
        		setUserMetaData(userMeta);
        		setHasActiveSubscription(true);        	
     	 	}
    	} 
    	catch (error) 
    	{
			logout();
    	}
    	finally
    	{
//     		console.log('done handling errors')
    	}

  	}

  useEffect(() => {
    getUserMetadata()
  }, [user])


  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {
      (hasActiveSubscription) ? (
        <AuthContext.Provider value={userMetadata}>
          {props.children}
        </AuthContext.Provider>
      )  : (<AuthContext.Provider value={userMetadata}>
      		 {props.children}
      		</AuthContext.Provider>
      		)
       }
    </>
  )
}
