import React, { useEffect, useState ,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { IconButton, InputAdornment } from '@material-ui/core'
import { FaCalendar } from 'react-icons/fa'

// import { SelectListComponent } from 'shared-components';

const RecipeDetail = () => {
  const data = useContext(AuthContext)
  const history = useHistory();
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [transformations, setTransformations] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [targetTypes, setTargetTypes] = useState([]);
  const [buildStatusCodes, setBuildStatusCodes] = useState([]);
  const [desktops, setDesktops] = useState([]);
  const [projects, setProjects] = useState([]);
  const [username, setUsername] = useState("");
  const [lastUpdateUsername, setLastUpdatedUsername] = useState("");
  const [recipe, setRecipe] = useState({
    portalRecipeId: id,
    portalRecipeName: "",
    portalRecipeDescription: "",
    securityContextId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    userProjectId: 0,
    userWorkspaceId: 0,
    targetTypeCode: 0,
    portalAccessServer: "",
    portalAccessPath: "",
    portalAccessProtocol: "",
    portalAccessUsername: "",
    portalAccessPassword: "",
    buildStatusCode: 0,
    transformationId: 0,
  });
  const [state, setState] = useState({
    transformations: [],
    projects: [],
    desktops: [],
    targetTypes: [],
    buildStatusCodes: [],
  });

  useEffect(() => {
    //console.log(id);
    handleOnLoad();
    //console.log("rec", recipe);
   // window.addEventListener("message", receiveMessage, false);
  }, []);

  useEffect(() => {
    getLastUser(recipe.lastUpdateUser);
  }, [recipe.lastUpdateUser]);

  const getLastUser = async (uid) => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/users/${uid}`,globalConfig.hardcoded_header)
      .then((res) => {
        const user = res.data;
        //console.log("user", user);
        setLastUpdatedUsername(user.username);
      });
  };

  useEffect(() => {
    getUser(recipe.createUser);
  }, [recipe.createUser]);

  const getUser = async (uid) => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/users/${uid}`,globalConfig.hardcoded_header)
      .then((res) => {
        const user = res.data;
        //console.log("user", user);
        setUsername(user.username);
      });
  };

  const receiveMessage = (event) => {
    //console.log("dada");
    console.log(event.data.message);
  };

  const yourMethod = (word) => {
    alert(word);
  };

  /* 	useEffect(()=>{
		console.log('rec22',recipe)
		
	},[recipe]) */

  const handleOnLoad = async () => {
    const { data: instructions } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/EVENT_TRIGGER_INSTRUCTIONS`,
      globalConfig.hardcoded_header);
   

    setInstructions(instructions);

    const { data: dataAppTypes } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/APPLICATION_TYPES`,
		globalConfig.hardcoded_header);

    setTargetTypes(dataAppTypes);

    const { data: dataBuildStatusCodes } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/BUILD_STATUS`,globalConfig.hardcoded_header);

    setBuildStatusCodes(dataBuildStatusCodes);
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/${recipe.portalRecipeId}`,
        globalConfig.hardcoded_header)
      .then((res) => {
        const recipe = res.data;
        setRecipe(recipe);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,globalConfig.hardcoded_header)
      .then((res) => {
        const transformations = res.data;

        setTransformations(transformations);
      });
    
 
    await axios
      .get(
         `${globalConfig.apiDatahubUrl}/user-workspaces/`,globalConfig.hardcoded_header)
      .then((res) => {
        const desktops = res.data;
        console.log("lii")
        console.log("deks",desktops)
        setDesktops(desktops);
      });
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/user-projects/`,globalConfig.hardcoded_header)
      .then((res) => {
        const projects = res.data;

        setProjects(projects);
      });
      await axios
      .get(
        `${globalConfig.apiMainUrl}/event-triggers/`,globalConfig.hardcoded_header)
      .then((res) => {
        const triggers = res.data;

        setTriggers(triggers);
      });

    //handleClear();
  };
  const handleClear = () => {
    setState({
      recipe: {
        portalRecipeId: 0,
        portalRecipeName: "",
        portalRecipeDescription: "",
        securityContextId: 0,
        createUser: 0,
        createDate: new Date(),
        lastUpdateUser: 0,
        lastUpdateDate: new Date(),
        userProjectId: 0,
        userWorkspaceId: 0,
        targetTypeCode: 0,
        portalAccessServer: "",
        portalAccessPath: "",
        portalAccessProtocol: "",
        portalAccessUsername: "",
        portalAccessPassword: "",
        buildStatusCode: 0,
        transformationId: 0,
      },
    });
  };

  const handleDelete = () => {};

  const handleDeploy = () => {};

  const handleSave = () => {
    /* 	recipe.targetTypeCode =  Number(recipe.targetTypeCode); 
		recipe.buildStatusCode = Number(recipe.buildStatusCode); 
    recipe.transformationId = Number(recipe.transformationId); */
  

    var url = `${globalConfig.apiDatahubUrl}/portal-recipes/${id}`;
    axios
      .patch(url, recipe, globalConfig.hardcoded_header)
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
          window.location.reload();
        } else {
          setMessage("Failed");
        }
        console.log(message);
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    console.log("message info - " + message);

    //handleClear();
  };

  return (
    <form>
      <div className="container-fluid">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>Recipe Detail</h4>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
            className="btn btn-primary"
          >
            Go Back
          </div>
        </div>

        <hr />

        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Name">
            Recipe Name
          </label>
          <input
            className="form-control input-medium col-sm-8"
            id="portalRecipeName"
            value={recipe.portalRecipeName}
            onChange={(e) =>
              setRecipe({ ...recipe, portalRecipeName: e.target.value })
            }
          ></input>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Description">
            Recipe Description
          </label>
          <textarea
            className="form-control input-big col-sm-8"
            id="portalRecipeDescription"
            value={recipe.portalRecipeDescription}
            onChange={(e) =>
              setRecipe({ ...recipe, portalRecipeDescription: e.target.value })
            }
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Project">
            Project
          </label>
          <select
            id="userProjectId"
            className="form-control input-big col-sm-8"
            value={recipe.userProjectId}
          >
            <option>Select project</option>
            {projects &&
              projects.map((project) => (
                <option
                  key={project.userProjectId}
                  value={project.userProjectId}
                >
                  {project.projectName}
                </option>
              ))}
            {/*<option value={ombUserProjectsForm.getUserProjectList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Desktop">
            Desktop
          </label>
          <select
            id="userDesktopId"
            className="form-control input-big col-sm-8"
            value={recipe.userWorkspaceId}
          >
            {desktops &&
              desktops.map((desktop) => (
                <option
                  key={desktop.userWorkspaceId}
                  value={desktop.userWorkspaceId}
                >
                  {desktop.workspaceName}
                </option>
              ))}
            {/*<option value={ombUserDesktopsForm.getUserDesktopList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Target Type">
            Target Type
          </label>
          <select
            id="targetTypeId"
            className="form-control input-big col-sm-8"
            value={recipe.targetTypeCode}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                targetTypeCode: parseInt(e.target.value),
              })
            }
          >
            {targetTypes &&
              targetTypes.map((targetType) => (
                <option
                  key={targetType.systemCodeValueId}
                  value={targetType.systemCodeValueId}
                >
                  {targetType.systemCodeValueValue}
                </option>
              ))}
          </select>
          {/*
				<SelectListComponent IdName="targetTypeCode" onChange={handleChange} entityName ={"system-code-values"} 
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"84"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"}/>

           			<select id="targetTypeCode" className="form-control input-big col-sm-8" value={recipe.targetTypeCode } onChange={handleChange}>
				<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('APPLICATION_TYPES')}/>
  				</select>		
				*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Transformation">
            Transformation
          </label>
          <select
            id="transformationId"
            className="form-control input-big col-sm-8"
            value={recipe.transformationId}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                transformationId: parseInt(e.target.value),
              })
            }
          >
            {transformations &&
              transformations.map((transformation) => (
                <option
                  key={transformation.transformationId}
                  value={transformation.transformationId}
                >
                  {transformation.transformationName}
                </option>
              ))}
            {/*<option value={ombTransformationForm.transformations}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label
            className="ContentTitle col-sm-2"
            value="Build Status (Latest)"
          >
            Build Status (Latest)
          </label>
          <select
            id="targetTypeId"
            className="form-control input-big col-sm-8"
            value={recipe.buildStatusCode}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                buildStatusCode: parseInt(e.target.value),
              })
            }
          >
            {buildStatusCodes &&
              buildStatusCodes.map((build) => (
                <option
                  key={build.systemCodeValueId}
                  value={build.systemCodeValueId}
                >
                  {build.systemCodeValueValue}
                </option>
              ))}
          </select>
          {/*
				<SelectListComponent IdName="buildStatusCode" onChange={handleChange} entityName ={"system-code-values"} 
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"94"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"}/>
	           		<select id="buildStatusCode" className="form-control input-big col-sm-8" value={recipe.buildStatusCode } onChange={handleChange}>
				<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('BUILD_STATUS')}/>
  				</select>
				*/}
        </div>

        <hr />
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Created By">
            Created By{" "}
          </label>
          <input
            className="form-control input-xlarge col-sm-2"
            id="createUser"
            readonly="true"
            value={username}
          ></input>
          <label className="ContentTitle col-sm-1" value="On">
            {" "}
            On{" "}
          </label>
          <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(recipe.createDate)}
                    disabled
                   
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <FaCalendar size={14} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Last Updated By">
            Last Updated By
          </label>
          <input
            className="form-control input-xlarge col-sm-2"
            id="lastUpdateUser"
            readonly="true"
            value={lastUpdateUsername}
          ></input>
          <label className="ContentTitle col-sm-1" value="On">
            On
          </label>
          <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(recipe.lastUpdateDate)}
                    disabled
                    readOnly="true"
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    //format="do MMMM, yyyy hh:mm aa"
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                         
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
        </div>

        <br />
        <hr />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "space-around",
            }}
            border="0"
            columns="3"
          >
            <div
              className="btn btn-success"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </div>
            &nbsp;
            <button
              className="btn btn-danger"
              value="Delete"
              onClick={handleDelete}
            >
              Delete
            </button>
            &nbsp;
            <button
              className="btn btn-warning"
              value="Deploy"
              onClick={handleDeploy}
            >
              Deploy
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default RecipeDetail;
