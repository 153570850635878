import React, {useState,useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link,useHistory } from 'react-router-dom';
// import { SelectListComponent } from 'shared-components';
import {ToastProvider,useToasts} from "react-toast-notifications"
import { globalConfig } from "../config";
import axios from 'axios';
import Pagination from "../utils/Paginate";

const  RecipeStepSearch =()=> {

		const data = useContext(AuthContext)
		const history = useHistory()
		const {addToast}=useToasts();
		const [selectedRecipeId,setSelctedRecipeId] =  useState(0)
		const [recipes,setRecipes] = useState([])
		const [recipeSteps,setRecipeSteps] = useState([])
		const [currentPage, setCurrentPage] = useState(1);
		
		//this.handleDelete = this.handleDelete.bind(this);
	
	useEffect(()=>{
		handleOnLoad();
	},[]);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	//handler for page
	const handlePage = (page) => {
	  setCurrentPage(page);
	};
	const indexOfLastData = currentPage * globalConfig.dataPerPage;
	const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
	const currentData = recipeSteps.slice(
	  indexOfFirstData,
	  indexOfLastData
	);

	const handleOnLoad = async() => {
  		await axios.get(`${globalConfig.apiDatahubUrl}/portal-recipes/`, globalConfig.hardcoded_header).then(res => {
		const recipes = res.data;
	
		 setRecipes(recipes)
		 console.log("recs",recipes)
		 setSelctedRecipeId(recipes[0].portalRecipeId)
		 axios.get(`${globalConfig.apiDatahubUrl}/recipe-steps?filter[where][recipeId]=${recipes[0].portalRecipeId}`, globalConfig.hardcoded_header).then(res => {
			const recipeSteps = res.data;
  			setRecipeSteps(recipeSteps);
		});

		});
	
	}
	const handleDelete = async (id) => {
		console.log("id", id);
		console.log("deleting step - " + id);
		await axios
		  .delete(
			`${globalConfig.apiDatahubUrl}/recipe-steps/${id}`,
			{headers:{
      Authorization: `Bearer ${data.token}`,
    }},
		  )
		  .then((res) => {
			if (res.status === 200) {
				addToast("Recipe step deleted succesfully", {
                autoDismiss: true,
                appearance: 'success',
              })
			  window.location.reload()
			} else {
				addToast("An error occured", {
					autoDismiss: true,
					appearance: 'success',
				  })
			}
		  })
		  .catch((err) => {
			console.log("error - " + err);
			addToast("An error occured", {
				autoDismiss: true,
				appearance: 'success',
			  })
		  });
		//window.location.href = "/RecipeStepSearch";
	  };
	const handleChange = (event) => {
		let rid = event.target.value;
		console.log('rid ' + rid);
		setSelctedRecipeId(rid)
		axios.get(`${globalConfig.apiDatahubUrl}/recipe-steps?filter[where][recipeId]=${event.target.value}`, globalConfig.hardcoded_header).then(res => {
			const recipeSteps = res.data;
  			setRecipeSteps(recipeSteps);
		});
	}

	

	
	return (
	<form>
	<div className="container-fluid">
		<div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
		<div style={{pointer:"cursor"}} onClick={()=>history.push("/RecipeStepCreate")} className="btn btn-success">
			Create Recipe Step
		</div>
		</div>
		<h4>Recipe Steps</h4>
		<hr />
	            <div className="form-group row">
	            <label className="ContentTitle" value="Recipe">Recipe</label>
	            <select className="form-control input-xlarge col-sm-8" id="recipeId" value={selectedRecipeId} id="recipeId" onChange={handleChange}>
			{recipes && recipes.map((recipe) => <option key={recipe.portalRecipeId} value={recipe.portalRecipeId}>{recipe.portalRecipeName}</option>)}
	            </select>
			</div>
	            <div className="form-group row">
			<table className="table table-bordered table-striped">
			<thead>
	                        <tr>
					<th scope="col">#</th>
					<th scope="col">Recipe Step</th>
					<th scope="col">Position</th>
					<th scope="col">Action</th></tr>
			</thead>
			<tbody>{currentData && currentData.map(({recipeStepId, recipeStepName, description, recipeSeqPos}) => {
				return <tr>
					<td><Link to={{ pathname: `/RecipeStepDetail/${recipeStepId}/${selectedRecipeId}` }}>{recipeStepId}</Link></td>
					<td><Link to={{ pathname: `/RecipeStepDetail/${recipeStepId}/${selectedRecipeId}` }}>{recipeStepName}</Link></td>
					<td><Link to={{ pathname: `/RecipeStepDetail/${recipeStepId}/${selectedRecipeId}` }}>{recipeSeqPos}</Link></td>
					<td><div style={{cursor:"pointer"}} className="btn btn-danger" onClick={() => handleDelete(recipeStepId) }>Delete</div></td>
				</tr>
	                        }) 
	                }
	                </tbody>

	                </table>
	            </div>
	
	        </div>
	<Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={recipeSteps.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
	</form>
);



}

export default RecipeStepSearch;
