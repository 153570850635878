import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link } from "react-router-dom";
import axios from "axios";
import { v4 as uuidV4 } from 'uuid'

import { globalConfig } from "../config";

const querystring = require("querystring");

const CreateTrigger = () => {
  const data = useContext(AuthContext)
  const [message, setMessage] = useState("");
  const [eventTrigger, setEventTrigger] = useState({
    eventTriggerId: uuidV4(),
    eventTriggerName: "",
    eventTriggerNumber: "",
    transformerId: 0,
    description: "",
    createUser: "",
    createDate: new Date(),
    lastUpdateUser: "",
    lastUpdateDate: new Date(),
    leftOperand: "",
    rightOperand: "",
    operator: "",
    instruction: "",
    preCondition: "",
    postCondition: "",
    condition: "",
    type: "",
  });
  const [conditions, setConditions] = useState([]);
  const [transformations, setTransformations] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [operators, setOperators] = useState([]);
  const [state, setState] = useState({
    triggers: [],
    transformations: [],
    recipes: [],
  });

  useEffect(() => {
    handleOnLoad();
  }, []);


  const handleOnLoad = async () => {
    const { data: instructions } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/EVENT_TRIGGER_INSTRUCTIONS`,
      globalConfig.hardcoded_header
    );
   // console.log("conds", instructions);

    setInstructions(instructions);

    const { data: operators } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/EVENT_TRIGGER_OPERATORS`,
      globalConfig.hardcoded_header
    );
    console.log("operators", operators);

    setOperators(operators);

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipes = res.data;
        setState({
          recipes: [
            {
              portalRecipeId: 0,
              portalRecipeName: "Select a conditional value",
            },
          ].concat(recipes),
        });
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation = res.data;
        console.log("trans", transformation);
        setTransformations(
          [
            { transformerId: 0, transformationName: "Select a transformation" },
          ].concat(transformation)
        );
      });

      await axios
      .get(
        `${globalConfig.apiMainUrl}/user-profile-service/userprofile`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const user = res.data;
        
        setEventTrigger({...eventTrigger, createUser:user.user.username,lastUpdateUser:user.user.username});
        
      });
  };

  function handleChange(event) {
    //console.log(event);
    var target = event.target;

    setEventTrigger({ ...eventTrigger, [target.id]: target.value });
  }

  const handleCreate = async() => {
	  console.log(JSON.stringify(eventTrigger))
    var url = `${globalConfig.apiDatahubUrl}/event-triggers`;

     await axios
      .post(
        url,
        eventTrigger,
        globalConfig.hardcoded_header
      )
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
          window.location.replace("/triggers");
        } else {
          setMessage("Failed");
        }
        console.log(message);
        //
      })
      .catch((err) => {
        console.log("error - " + err);
      });
	console.log("message info - " + message);
	
  };
  return (
    <>
      <form>
        <h4>Create Event Trigger</h4>
        <hr />
        <div className="container-fluid">
          <div id="content">
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="EventTriggerName">
                {" "}
                Event Trigger Name
              </label>
              <input
                className="form-control input-xlarge col-sm-8"
                id="eventTriggerName"
                value={eventTrigger.eventTriggerName}
                onChange={handleChange}
              ></input>{" "}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2">
                Event Trigger Description
              </label>
              <input
                className="form-control input-xlarge col-sm-8"
                id="description"
                rows="4"
                cols="65"
                value={eventTrigger.description}
                onChange={handleChange}
              ></input>
            </div>
            <div className="form-group row">
            <label className="ContentTitle col-sm-2">
              Event Trigger Number
            </label>
            <input
              className="form-control input-xlarge col-sm-8"
              id="eventTriggerNumber"
              rows="4"
              cols="65"
              value={eventTrigger.eventTriggerNumber}
              onChange={handleChange}
              type="number"
            ></input>
          </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Instruction">
                {" "}
                Instruction
              </label>

              <select
                id="instruction"
                value={eventTrigger.instruction}
                className="form-control input-xlarge col-sm-8"
                onChange={handleChange}
              >
                 {instructions &&
                instructions.map((instruction) => (
                  <option
                    key={instruction.systemCodeValueId}
                    value={instruction.systemCodeValueId}
                  >
                    {instruction.systemCodeValueValue}
                  </option>
                ))}
              </select>

              {/*
				<select id="instruction" value={eventTrigger.instruction }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_INSTRUCTIONS')}"/>
  				</select>
			*/}
            </div>
            <div className="form-group row">
              <label
                className="ContentTitle col-sm-2"
                value="Condition - #{ombEventTriggerForm.selectedConditionCode }"
              >
                Condition
              </label>
              <select />

              {/*
			<SelectListComponent IdName="condition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<SelectListComponent IdName="condition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<select id="condition" onchange="submit()" 
           			valueChangeListener="#{ombEventTriggerForm.conditionChangeListener }"
           			value="#{ombEventTriggerForm.selectedConditionCode }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombUserSession.getSystemLookupCodesBean().getSystemCodesSelectItems()}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/>
  				</select>
			*/}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Pre Condition">
                {" "}
                Pre Condition{" "}
              </label>
              <select />

              {/*	
			<SelectListComponent IdName="preCondition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<SelectListComponent IdName="preCondition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
           			<select id="preCondition" value={eventTrigger.preCondition }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox(ombEventTriggerForm.selectedConditionCodeName)}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/> 
  				</select>
			*/}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Post Condition">
                Post Condition
              </label>
              <select />

              {/*
			<SelectListComponent IdName="postCondition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<SelectListComponent IdName="postCondition" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
	           		<select id="postCondition" value={eventTrigger.postCondition }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox(ombEventTriggerForm.selectedConditionCodeName)}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/> 
  				</select>
			*/}
            </div>
          </div>

          <hr />
          <h4>Operations</h4>
          <div columns="2">
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Left Operand">
                Left Operand
              </label>
              <select />
              {/*
           			<SelectListComponent IdName="leftOperand" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"244"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<select id="leftOperand" value={eventTrigger.leftOperand }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERANDS')}"/>
  				</select>
				*/}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Operator">
                Operator
              </label>
              <select
                id="operator"
                value={eventTrigger.operator}
                className="form-control input-xlarge col-sm-8"
                onChange={handleChange}
              >
                <option key="" value="">
                  Select Operator
                </option>
                {operators &&
                  operators.map((operator) => (
                    <option
                      key={operator.systemCodeValueId}
                      value={operator.systemCodeValueId}
                    >
                      {operator.systemCodeValueValue}
                    </option>
                  ))}
              </select>
              {/*
				<SelectListComponent IdName="operator" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"243"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
           			<select id="operator" value={eventTrigger.operator }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERATORS')}"/>
  				</select>
				*/}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="RightOperand">
                Right Operand
              </label>
              <select />

              {/*
           			<SelectListComponent IdName="rightOperand" onChange={this.handleChange} entityName ={"system-code-values"}  onChange={this.handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"244"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={contentSourceType} />
				<select id="rightOperand" value={eventTrigger.rightOperand }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERANDS')}"/>
  				</select>
				*/}
            </div>
            <div className="form-group row">
              <label className="ContentTitle col-sm-2" value="Transformation">
                {" "}
                Transformation
              </label>
              <select
                id="transformerId"
                value={eventTrigger.transformerId}
                className="form-control input-xlarge col-sm-8"
                onChange={handleChange}
              >
                {transformations.map((transformation) => (
                  <option
                    key={transformation.transformationId}
                    value={transformation.transformationId}
                  >
                    {transformation.transformationName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <hr />
          <div>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              border="0"
              columns="3"
            >
              <div
                className="btn btn-success"
                value="Save"
                onClick={() => {
                  handleCreate();
                }}
              >
                Save
              </div>
              &nbsp;
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateTrigger;
