import React, { Component } from "react";
import { Link } from "react-router-dom";
import { generateKeyPair } from "crypto";
const querystring = require("querystring");

export default class HomePage extends Component {
  constructor(props) {
    //console.log(props);
    super(props);
  }
  render() {
    return (
        <form onSubmit={this.handleSubmit}> 

            <div class="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="col-md">


            <h3>Projects</h3>
        <p>
          <a href="/Projects" className="ContentTitle">
             Search
          </a>
        </p>
      
                        </div>
                        <div class="col-md">


        <h3>Recipes</h3>
        <p>
          <a href="/ProjectRecipes" className="ContentTitle">
            Search
          </a>
        </p>
        <p>
          <a href="/RecipeDetail" className="ContentTitle">
            Recipes detail
          </a>
        </p>
        <p>
          <a href="/CreateRecipe" className="ContentTitle">
            Create Recipes
          </a>
                            </p>
                            <p>
          <a href="/LayoutDetails" className="ContentTitle">
            Layout
          </a>
        </p>
        <p>
          <a href="/BuildDetails" className="ContentTitle">
          Build a Project
          </a>
        </p>
        <p>
          <a href="/DeployDetails" className="ContentTitle">
          Deploy a Project
          </a>
                            </p>
                        </div>
                        <div class="col-md">

        <h3 >Recipes Steps</h3>
        <p>
          <a href="/RecipeStepSearch" className="ContentTitle">
            Search
          </a>
        </p>
        <p>
          <a href="/RecipeStepDetail" className="ContentTitle">
            Recipes step details
          </a>
        </p>
        <p>
          <a href="/RecipeStepCreate" className="ContentTitle">
            Create Recipes Steps
          </a>
        </p>
        

                        </div>
                        <div class="col-md">

                            <h3> Triggers </h3>
        <p>
          <a href="/Triggers" className="ContentTitle">
            Search
          </a>
        </p>
        <p>
          <a href="/TriggerDetail" className="ContentTitle">
            Trigger Details
          </a>
        </p>
        <p>
          <a href="/TriggerCreate" className="ContentTitle">
            Create a Trigger
          </a>
                            </p>
                        </div>
                        <div class="col-md">

        <h3> Transformers </h3>
        <p>
          <a href="/transformers" className="ContentTitle">
            Search
          </a>
        </p>
        <p>
          <a href="/manage-transformer" className="ContentTitle">
            Manage Transformer
          </a>
        </p>
        <p>
          <a href="/create-transformer" className="ContentTitle">
            Create A Transformer
          </a>
        </p>

                        </div>
                    </div>
                </div>
            </div>
            
      </form>
    );
  }
}