import React, {useEffect,useState} from 'react';
import { Link,useHistory,useParams } from 'react-router-dom';
import axios from 'axios';
import { globalConfig } from "../config";
const querystring = require('querystring');


const DeployDetails =()=> {

	const {id}=useParams();
	const history = useHistory();
	const [message,setMessage] = useState("")
	const [transformations, setTransformations] = useState([]);
	const [targetTypes,setTargetTypes] = useState([])
	const [buildStatusCodes, setBuildStatusCodes] = useState([]);
	const [username,setUsername] = useState("");
	const [lastUpdateUsername,setLastUpdatedUsername] = useState("")
	const [recipe,setRecipe] = useState({
		portalRecipeId: id,
		portalRecipeName: "",
		portalRecipeDescription: "",
		securityContextId: 0,
		createUser: 0,
		createDate: new Date(),
		lastUpdateUser: 0,
		lastUpdateDate: new Date(),
		userProjectId: 0,
		userWorkspaceId: 0,
		targetTypeCode: 0,
		portalAccessServer: "",
		portalAccessPath: "",
		portalAccessProtocol: "",
		portalAccessUsername: "",
		portalAccessPassword: "",
		buildStatusCode: 0,
		transformationId: 0,
	})

const [state,setState] = useState({
	deployDetailsObject: {},
	entities: [],
	projects:[]
});


useEffect(()=>{
	handleOnLoad()
},[])


useEffect(() => {
    getLastUser(recipe.lastUpdateUser)
 }, [recipe.lastUpdateUser]);

 const getLastUser =async(uid)=>{
   axios
   .get(
     `${globalConfig.apiDatahubUrl}/users/${uid}`,
     globalConfig.hardcoded_header
   )
   .then((res) => {
     const user = res.data;
     //console.log("user", user);
     setLastUpdatedUsername(user.username);
   });

 }

  useEffect(() => {
     getUser(recipe.createUser)
  }, [recipe.createUser]);

  const getUser =async(uid)=>{
    axios
    .get(
      `${globalConfig.apiDatahubUrl}/users/${uid}`,
      globalConfig.hardcoded_header
    )
    .then((res) => {
      const user = res.data;
      //console.log("user", user);
      setUsername(user.username);
      
    });

  }


const handleSave = () => {
    /* 	recipe.targetTypeCode =  Number(recipe.targetTypeCode); 
  recipe.buildStatusCode = Number(recipe.buildStatusCode); 
  recipe.transformationId = Number(recipe.transformationId); */
 

    var url = `${globalConfig.apiDatahubUrl}/portal-recipes/${id}`;
    axios
      .patch(url, recipe, globalConfig.hardcoded_header)
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
          window.location.reload();
        } else {
          setMessage("Failed");
        }
        console.log(message);
      })
      .catch((err) => {
        console.log("error - " + err);
      });
    console.log("message info - " + message);

    //handleClear();
  };

  const handleOnLoad = async () => {
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/${recipe.portalRecipeId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipe = res.data;

        //console.log("res", recipe);
        setRecipe(recipe);
      });
	await axios
	.get(
	  `${globalConfig.apiDatahubUrl}/transformations/`,
	  globalConfig.hardcoded_header
	)
	.then((res) => {
	  const transformationsx = res.data;
	  setTransformations(transformationsx);
	});
	const { data: dataAppTypes } = await axios.get(
		`${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/APPLICATION_TYPES`,
		globalConfig.hardcoded_header
	  );
	 setTargetTypes(dataAppTypes)

	await axios
	.get(
	  `${globalConfig.apiMainUrl}/user-projects-by-id/`,
	  globalConfig.hardcoded_header
	)
	.then((res) => {
	  const projects = res.data;
	  setState({...state, projects });
	  //console.log("project is", projects);
	});
	const { data: dataBuildStatusCodes } = await axios.get(
		`${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/BUILD_STATUS`,
		globalConfig.hardcoded_header
	  );
		//console.log("data", dataBuildStatusCodes)
	  setBuildStatusCodes(dataBuildStatusCodes);


  await  axios.get(`${globalConfig.apiDatahubUrl}/DeployDetails/`).then(res => {
  const connectors = res.data;
  setState({ entities:connectors });
  //console.log(res.data[0]);
	});
	
}


return (
	<form>
		<div style={{display:"flex",justifyContent:"flex-end"}}>
		<div style={{cursor:"pointer",margin:"0px 5px"}} onClick={()=>history.goBack()} className="btn btn-primary">Go Back</div>
		</div>
		<h4>Deploy Details</h4>
		<br/>
		<div className="container-fluid">
		<div className="form-group row">
		   		  <label className="ContentTitle col-sm-2" value="Recipe Name">Recipe Name</label>
	           	<input className="form-control input-xlarge col-sm-8" id="portalRecipeName"    value={recipe.portalRecipeName}
          onChange={(e) =>
            setRecipe({ ...recipe, portalRecipeName: (e.target.value) })
          }>
	           	</input> 
			{/* 	<label className="ContentTitle col-sm-2 text-danger"
					rendered="#{ombPortalLayoutForm.currentPortalRecipe._portal_recipe_id eq 0}"
					value=" Please Note! This recipe has not been saved." 
				>
					Please Note! This recipe has not been saved.
				</label> */}
			    </div>	           	
	            <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Project">
            Select Project
          </label>
          <select
            id="projectId"
            className="form-control input-big col-sm-8"
			value={recipe.userProjectId}
			onChange={(e) =>
			  setRecipe({ ...recipe, userProjectId: parseInt(e.target.value) })
			}
          >
            <option>Select Project</option>
            {state.projects.length > 0 &&
              state.projects?.map((project) => (
                <option
                  key={project.userProjectId}
                  value={project.userProjectId}
                >
                  {project.projectName}
                </option>
              ))}
            {/*<option value={ombUserProjectsForm.getUserProjectList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label value="Project Target Type" className="ContentTitle col-sm-2">
            Project Target Type
          </label>
          <select
            id="targetTypeCode"
            className="form-control input-xlarge col-sm-8"
			value={recipe.targetTypeCode}
			onChange={(e) =>
			  setRecipe({ ...recipe, targetTypeCode: parseInt(e.target.value) })
			}
          >
            {targetTypes &&
              targetTypes.map((targetType) => (
                <option
                  key={targetType.systemCodeValueId}
                  value={targetType.systemCodeValueId}
                >
                  {targetType.systemCodeValueValue}
                </option>
              ))}
          </select>
        </div>	
				<div className="form-group row">
				
					<label value="Builder/Transformation" className="ContentTitle col-sm-2">Builder/Transformation</label>
				{/* 	<select  id="transformationId" value="#{ombPortalLayoutForm.currentPortalRecipe._transformation_id}" className="form-control input-xlarge col-sm-8" >
							<option value="#{ombTransformationForm.transformations}" />
						</select> */}
						 <select
            id="transformationId"
            className="form-control input-big col-sm-8"
			value={recipe.transformationId}
			onChange={(e) =>
			  setRecipe({ ...recipe, transformationId: parseInt(e.target.value) })
			}
          >
            <option>Select Transformation</option>
            {transformations &&
              transformations.map((transformation) => (
                <option
                  key={transformation.transformationId}
                  value={transformation.transformationId}
                >
                  {transformation.transformationName}
                </option>
              ))}
            {/*<option value={ombTransformationForm.transformations}/>*/}
          </select>
				</div>	
				<div className="form-group row">
					<label value="Target Portal Description" className="ContentTitle col-sm-2">Target Portal Description</label>
					<input id="portalRecipeDescription" cols="100" rows="1" className="form-control input-big"  value={recipe.portalRecipeDescription}
          onChange={(e) =>
            setRecipe({ ...recipe, portalRecipeDescription: (e.target.value) })
          }  className="form-control input-xlarge col-sm-8" />
				</div>
				<div className="form-group row">
					<label value="Build Status" className="ContentTitle col-sm-2"> Build Status</label>
					{/* <select  id="buildStatusCode" value="#{ombPortalLayoutForm.currentPortalRecipe._build_status_code}" className="form-control input-xlarge col-sm-8">
							<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('BUILD_STATUS')}" />
						</select> */}
						 <select
            id="targetTypeId"
            className="form-control input-big col-sm-8"
            value={recipe.buildStatusCode}
            onChange={(e) =>
              setRecipe({ ...recipe, buildStatusCode: parseInt(e.target.value) })
            }
          >
            <option>Select Build Status</option>
            {buildStatusCodes &&
              buildStatusCodes.map((build) => (
                <option
                  key={build.systemCodeValueId}
                  value={build.systemCodeValueId}
                >
                  {build.systemCodeValueValue}
                </option>
              ))}
          </select>

                </div>
				<div className="form-group row">
					<label className="ContentTitle col-sm-2" value="Portal Server">Portal Server</label>
					<input className="form-control input-xlarge col-sm-8" id="portal_server" 
					 value={recipe.portalAccessServer}
					 onChange={(e) =>
					   setRecipe({ ...recipe, portalAccessServer: (e.target.value) })
					 }
					/>
				</div>
				<div className="form-group row">
					<label className="ContentTitle col-sm-2" value="Portal Path">Portal Path</label>
					   <input 
					    className="form-control input-xlarge col-sm-8"
					    id="portal_path" 
					    value={recipe.portalAccessPath}
						onChange={(e) =>
						  setRecipe({ ...recipe, portalAccessPath: (e.target.value) })
						}
					   />
				</div>			
				
					
					<div className="form-group row">
					<label className="ContentTitle col-sm-2" value="Portal Access">Portal Access </label>
					{/* <label className="ContentTitle col-sm-2" value="Xfer Mode " >Xfer Mode </label> */}
					
					<select 
					    id="xferMode" 
						value={recipe.portalAccessProtocol}
						onChange={(e) =>
						  setRecipe({ ...recipe, portalAccessProtocol: (e.target.value) })
						} 
						className="form-control input-xlarge col-sm-8">
	    				<option itemValue="0" itemLabel="Protocol" />
						<option itemValue="1" itemLabel="ftp"/>
						<option itemValue="2" itemLabel="sftp"/>
						<option itemValue="3" itemLabel="http"/>
					</select>
					</div>
					<div className="form-group row">
						<label className="ContentTitle col-sm-2" value="Username ">Username</label>
						<input className="form-control input-xlarge col-sm-8" maxlength="16" id="username" 
						 value={recipe.portalAccessUsername}
						 onChange={(e) =>
						   setRecipe({ ...recipe, portalAccessUsername: (e.target.value) })
						 }
						/>
				    </div>
					<div className="form-group row">
						<label className="ContentTitle col-sm-2" value="Password ">Password</label>
						<input className="form-control input-xlarge col-sm-8" maxlength="16" id="password" 
						 value={recipe.portalAccessPassword}
						 onChange={(e) =>
						   setRecipe({ ...recipe, portalAccessPassword: (e.target.value) })
						 }
						/>
		    	    </div>
					
				<div className="form-group row">
				<label value="Created By"  className="ContentTitle col-sm-2">Created By</label>
				
					<input readonly="true" className="form-control input-xlarge col-sm-6"
						 value={username}
						 onChange={(e) =>
						   setRecipe({ ...recipe, createUser: (e.target.value) })
						 }></input>
					<div className="form-group row">
					<label value="On"  style={{margin:"0px 10px"}}   className="ContentTitle col-sm-2">On</label>
					<input readonly="true" className="form-control input-xlarge col-sm-8"	
						value="#{ombPortalLayoutForm.currentPortalRecipe._create_date}"
						value={new Date(recipe.createDate)}
						onChange={(e) =>
						  setRecipe({ ...recipe, createDate: (e.target.value) })
						}
						>
					</input>
					</div>
				</div>
				<div className="form-group row">
				<label value="Last Updated By" className="ContentTitle col-sm-2"> Last Updated By</label>
					<input readonly="true" className="form-control input-xlarge col-sm-6"
						value="#{ombUserSession.getUserNameForId(ombPortalLayoutForm.currentPortalRecipe._last_update_user)}"
						value={lastUpdateUsername}
						onChange={(e) =>
						  setRecipe({ ...recipe, lastUpdateUser: (e.target.value) })
						}
						/>
					<div className="form-group row">
					<label style={{margin:"0px 10px"}} value="On" className="ContentTitle col-sm-2">On</label>
					
					<input value="#{ombPortalLayoutForm.currentPortalRecipe._last_update_date}"
					 value={new Date(recipe.lastUpdateDate)}
					 onChange={(e) =>
					   setRecipe({ ...recipe, lastUpdateDate: (e.target.value) })
					 } 
						readonly="true"  
						className="form-control input-xlarge col-sm-8">
					</input>
				</div>
				</div>
				
			<hr />
			<div style={{display:"flex",width:"100%",justifyContent:"flex-end"}}>
					<div style={{padding:"0px !important",display:"flex",justifyContent:"space-between",width:"55%",paddingLeft:"10px"}}>
			{/* 	<div style={{cursor:"pointer"}} className="btn yellow" value="&lt;&lt; Prev Step"
					onclick="switchTabs('tabDeploy','','tab_deploy','tab-pane','tabBuild','active','tab_build','tab-pane active')"
				 />
				 	<label className="btn yellow" value="Manage Transformations" />
					<label className="btn yellow" value="Project Layout Editor"/>
				  */}
				 <div style={{cursor:"pointer"}} onClick={handleSave} className="btn btn-secondary" value="Save Recipe" actionListener="#{ombPortalLayoutForm.saveBuildRecipe}">Save Recipe</div>
				<div style={{cursor:"pointer"}} className="btn btn-info" onclick="alert('Your build will now begin. Please wait or check back later for status.')" value="Build">Build</div>
				<div style={{cursor:"pointer"}} className="btn btn-dark" value="Publish" actionListener="#{ombPortalLayoutForm.deployRecipe }">Publish</div>
				<div style={{cursor:"pointer"}} className="btn btn-danger" value="Delete" actionListener="#{ombPortalLayoutForm.delete}" >Delete</div>
				<div style={{cursor:"pointer"}} className="btn btn-warning" value="Next Step >> "
					onclick="switchTabs('tabBuild','','tab_build','tab-pane','tabDeploy','active','tab_deploy','tab-pane active')"
				 >Next Step</div>
				</div>
			</div>
				
				
	</div>	
			
	</form>
);

}

export default DeployDetails;

