import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
const querystring = require("querystring");

const RecipeSearch = () => {
  const data = useContext(AuthContext)
    const history = useHistory();
    const [userData,setUserData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
  const [state, setState] = useState({
    portalRecipes: [],
  });

//   const sendToken = ()=>{
//    
//     window.parent.postMessage(
//       "come",
//       "http://localhost:3011/data"
// 
//     )
//    }
  
//   useEffect(() => {
//     sendToken();
//     window.addEventListener("message",function(e){
//       if(e.origin!=="http://localhost:3011")return;
//       setUserData(JSON.parse(e.data));
//       //console.log("passed",e.data)
//     })
//     handleOnLoad();
//   }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData = state.portalRecipes.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const handleOnLoad = () => {
    axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/`, globalConfig.hardcoded_header)
      .then((res) => {
        const portalRecipes = res.data;
        setState({ portalRecipes });
      });
  };

  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/portal-recipes?`;
    URL += `&filter[where][or][0][portalRecipeName][regexp]=/(${search})/i`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const recipes = res.data;
      //console.log(recipes);
      setState({
        ...state,
        portalRecipes: recipes,
      });
      //console.log(res.data[0]);
      setCurrentPage(1)
    });
  };

  const deleteRecipe = (id) => {
    axios
      .delete(
        `${globalConfig.apiDatahubUrl}/portal-recipes/${id}`, globalConfig.hardcoded_header)
      .then((res) => {
        const portalRecipes = res.data;
      });
  };

  return (
    <form>
      <div class="container-fluid">
		<div className="d-flex flex-row justify-content-between mb-3 mt-3">
			  <h4>Recipes</h4>
          <div
              onClick={() => history.push ({pathname:"/CreateRecipe" , state:{userData:userData}}) }
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="btn btn-success"
            >
              Create Recipe
            </div>
          </div>
     
      <div className="form-group">
          <input
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
        <div className="form-group row">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Recipe Name</th>
                {/*<th scope="col">Build Status</th>*/}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(
                ({ portalRecipeId, portalRecipeName, buildStatusCode }) => {
                  return (
                    <tr>
                      <td>
                        <Link
                          to={{ pathname: `/RecipeDetail/${portalRecipeId}` }}
                        >
                          {portalRecipeId}
                        </Link>
                      </td>
                      <td>
                      <Link
                          to={{ pathname: `/RecipeDetail/${portalRecipeId}` }}
                        >
                          {portalRecipeName}
                        </Link>
                      </td>
                      {/*<td><Link  to={{ pathname: "/RecipeDetail", search : `?id=${portalRecipeId}` }}>{buildStatusCode}</Link></td>*/}
                      <td>
                        <div style={{display:"flex",justifyContent:"space-around",width:"70%"}}>
                        {/*<button class="btn btn-success">Edit</button>&nbsp;*/}
                        <div style={{cursor:"pointer"}}
                          class="btn btn-warning"
                          onClick={() =>
                            history.push({pathname:`/LayoutDetails/${portalRecipeId}`})
                          }
                        >
                          Layout
                        </div>
                        <div style={{cursor:"pointer"}}
                          class="btn btn-success"
                          onClick={() =>
                            history.push(`/BuildDetails/${portalRecipeId}`)
                          }
                        >
                          Build
                        </div>
                        <div style={{cursor:"pointer"}}
                          class="btn btn-primary"
                          onClick={() =>
                            history.push(`/DeployDetails/${portalRecipeId}`)
                          }
                        >
                          Deploy
                        </div>
                        <div style={{cursor:"pointer"}}
                          class="btn btn-danger"
                          onClick={() =>
                            deleteRecipe(portalRecipeId)
                          }
                        >
                          Delete
                        </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={state.portalRecipes.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
    </form>
     
  );
};

export default RecipeSearch;
