import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link, useParams, useHistory } from "react-router-dom";
import { globalConfig } from "../config";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { IconButton, InputAdornment } from '@material-ui/core'
import { FaCalendar } from 'react-icons/fa'
import axios from "axios";
// import { SelectListComponent } from 'shared-components';
const querystring = require("querystring");

const TriggerDetail = () => {
  const data = useContext(AuthContext)
  const { id } = useParams();
  const [message,setMessage]=useState("")
  const history = useHistory();

  const [eventTrigger, setEventTrigger] = useState({
    
    eventTriggerId: "",
    eventTriggerName: "",
    eventTriggerNumber: "",
    transformerId: 0,
    description: "",
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    leftOperand: "",
    rightOperand: "",
    operator: "",
    instruction: "",
    preCondition: "",
    postCondition: "",
    condition: "",
    type:""
  });
  const [conditions, setConditions] = useState([]);
  const [transformations, setTransformations] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [operators, setOperators] = useState([]);
  const [state, setState] = useState({
    triggers: [],
    transformations: [],
    recipes: [],
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = async () => {
    const { data: instructions } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/EVENT_TRIGGER_INSTRUCTIONS`,
      globalConfig.hardcoded_header
    );
    console.log("conds", instructions);

    setInstructions(instructions);

    const { data: operators } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/EVENT_TRIGGER_OPERATORS`,
      globalConfig.hardcoded_header
    );
    console.log("operators", operators);

    setOperators(operators);

    await axios
      .get(
        `${globalConfig.apiMainUrl}/event-triggers/${id}`,
        //`${globalConfig.apiMainUrl}/event-triggers/`,
        //`http://localhost:5100/event-triggers/${id}`,
        // `${globalConfig.apiDatahubUrl}/portal-recipes/${recipe.portalRecipeId}`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const event = res.data;
        console.log("res", event);
        setEventTrigger(event);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const transformation = res.data;
        console.log("trans", transformation);
        setTransformations(
          [
            { transformerId: 0, transformationName: "Select a transformation" },
          ].concat(transformation)
        );
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/`,
        globalConfig.hardcoded_header
      )
      .then((res) => {
        const recipes = res.data;
        setState({
          recipes: [
            {
              portalRecipeId: 0,
              portalRecipeName: "Select a conditional value",
            },
          ].concat(recipes),
        });
      });

   
  };

  const handleChange = (event) => {
    //console.log(event);
    var target = event.target;
    console.log(target.id + ":" + target.value);
    setEventTrigger({
      ...eventTrigger, [target.id]: target.value 
    })
  };
  const handleSave = async() => {
    var url = `${globalConfig.apiDatahubUrl}/event-triggers/${id}`;
    const { _id, ...eventTriggerwithoutId } = eventTrigger;
    console.log("fdfd",JSON.stringify(eventTriggerwithoutId))

     await axios
      .patch(
        `${globalConfig.apiMainUrl}/event-triggers/${id}`,
        eventTriggerwithoutId,
        globalConfig.hardcoded_header
      )
      .then((response2) => {
        console.log("response.data");
        if (response2.status === 200) {
          setMessage("Success");
          window.location.reload();
        } else {
          setMessage("Failed");
        }
        console.log(message);
        //
      })
      .catch((err) => {
        console.log("error - " + err);
      });
  };
  const handleDelete = async() => {
    const  url = `${globalConfig.apiMainUrl}/event-triggers/${id}`;
    await axios
    .delete(
      
      url,
      globalConfig.hardcoded_header
    )
    .then((response2) => {
      console.log("response.data");
      if (response2.status === 200) {
        setMessage("Success");
        window.location.replace('/triggers');
      } else {
        setMessage("Failed");
      }
      console.log(message);
      //
    })
    .catch((err) => {
      console.log("error - " + err);
    });
  };

  return (
    <form>
		<div style={{ display: "flex", justifyContent: "space-between" }}>

      <h4>Event Trigger Details</h4>
      <div style={{cursor:"pointer",margin:"0px 5px"}} onClick={()=>history.goBack()} className="btn btn-primary">Go Back</div>
		</div>
      <hr />
      <div className="container-fluid">
        <div id="content">
          <div className="form-group row">
            <label className="ContentTitle col-sm-2">ID</label>
            <label>{eventTrigger?.eventTriggerId}</label>
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="EventTriggerName">
              {" "}
              Event Trigger Name
            </label>
            <input
              className="form-control input-xlarge col-sm-8"
              id="eventTriggerName"
              value={eventTrigger.eventTriggerName}
              onChange={handleChange}
            ></input>{" "}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2">
              Event Trigger Description
            </label>
            <input
              className="form-control input-xlarge col-sm-8"
              id="description"
              rows="4"
              cols="65"
              value={eventTrigger.description}
              onChange={handleChange}
            ></input>
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2">
              Event Trigger Number
            </label>
            <input
              className="form-control input-xlarge col-sm-8"
              id="eventTriggerNumber"
              rows="4"
              cols="65"
              value={eventTrigger.eventTriggerNumber}
              onChange={handleChange}
              type="number"
            ></input>
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Instruction">
              {" "}
              Instruction
            </label>

            <select
              id="instruction"
              value={eventTrigger.instruction}
              className="form-control input-xlarge col-sm-8"
              onChange={handleChange}
            >
              {instructions &&
                instructions.map((instruction) => (
                  <option
                    key={instruction.systemCodeValueId}
                    value={instruction.systemCodeValueId}
                  >
                    {instruction.systemCodeValueValue}
                  </option>
                ))}
            </select>

            {/*
				<select id="instruction" value={eventTrigger.instruction }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_INSTRUCTIONS')}"/>
  				</select>
			*/}
          </div>
          <div className="form-group row">
            <label
              className="ContentTitle col-sm-2"
              value="Condition - #{ombEventTriggerForm.selectedConditionCode }"
            >
              Condition
            </label>
            <select />

            {/*
			<SelectListComponent IdName="condition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />

				<SelectListComponent IdName="condition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />

				<select id="condition" onchange="submit()" 
           			valueChangeListener="#{ombEventTriggerForm.conditionChangeListener }"
           			value="#{ombEventTriggerForm.selectedConditionCode }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombUserSession.getSystemLookupCodesBean().getSystemCodesSelectItems()}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/>
  				</select>
			*/}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Pre Condition">
              {" "}
              Pre Condition{" "}
            </label>
            <select />

            {/*	
			<SelectListComponent IdName="preCondition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
				<SelectListComponent IdName="preCondition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />

           			<select id="preCondition" value={eventTrigger.preCondition }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox(ombEventTriggerForm.selectedConditionCodeName)}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/> 
  				</select>
			*/}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Post Condition">
              Post Condition
            </label>
            <select />

            {/*
			<SelectListComponent IdName="postCondition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"241"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />

				<SelectListComponent IdName="postCondition" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"240"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
	           		<select id="postCondition" value={eventTrigger.postCondition }" className="form-control input-xlarge col-sm-8">
           			<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox(ombEventTriggerForm.selectedConditionCodeName)}"></option>
					<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_CONDITIONS')}"/> 
  				</select>
			*/}
          </div>
        </div>

        <hr />
        <h4>Operations</h4>
        <div columns="2">
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Left Operand">
              Left Operand
            </label>
            <select />
            {/*
           			<SelectListComponent IdName="leftOperand" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"244"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
				<select id="leftOperand" value={eventTrigger.leftOperand }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERANDS')}"/>
  				</select>
				*/}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Operator">
              Operator
            </label>
			<select
              id="operator"
              value={eventTrigger.operator}
              className="form-control input-xlarge col-sm-8"
              onChange={handleChange}
            >
              {operators &&
                operators.map((operator) => (
                  <option
                    key={operator.systemCodeValueId}
                    value={operator.systemCodeValueId}
                  >
                    {operator.systemCodeValueValue}
                  </option>
                ))}
            </select>
            {/*
				<SelectListComponent IdName="operator" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"243"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
           			<select id="operator" value={eventTrigger.operator }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERATORS')}"/>
  				</select>
				*/}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="RightOperand">
              Right Operand
            </label>
            <select />

            {/*
           			<SelectListComponent IdName="rightOperand" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"244"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
				<select id="rightOperand" value={eventTrigger.rightOperand }" className="form-control input-xlarge col-sm-8">
				<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('EVENT_TRIGGER_OPERANDS')}"/>
  				</select>
				*/}
          </div>
          <div className="form-group row">
            <label className="ContentTitle col-sm-2" value="Transformation">
              {" "}
              Transformation
            </label>
            <select
              id="transformerId"
              value={eventTrigger.transformerId}
              className="form-control input-xlarge col-sm-8"
              onChange={handleChange}
            >
              {transformations &&
                transformations.map((transformation) => (
                  <option
                    key={transformation.transformationId}
                    value={transformation.transformationId}
                  >
                    {transformation.transformationName}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="CreatedBy">
            {" "}
            Created by
          </label>
          <input
            className="form-control input-xlarge col-sm-2"
            id="createdBy"
            readonly="true"
            value={eventTrigger.createUser}
          ></input>
          <label className="ContentTitle col-sm-1" value="On">
            On
          </label>
          <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(eventTrigger.createDate)}
                    disabled
                   
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <FaCalendar size={14} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Last Updated By">
            {" "}
            Last Updated By{" "}
          </label>
          <input
            className="form-control input-xlarge col-sm-2"
            id="lastUpdatedBy"
            readonly="true"
            value={eventTrigger.lastUpdateUser}
          ></input>
          <label className="ContentTitle col-sm-1" value="On">
            On
          </label>
          <div  className=" col-sm-7">
          <MuiPickersUtilsProvider    utils={DateFnsUtils}>
                  <DateTimePicker
                    value={new Date(eventTrigger.lastUpdateDate)}
                    disabled
                    style={{ width: '40%', border: '1px solid #d5d5d5', padding: 2, borderRadius: '.25rem' }}
                    format="MM/dd/yyyy"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <FaCalendar size={14} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}>
                  </DateTimePicker>
                </MuiPickersUtilsProvider>
          </div>
        </div>
        <br />
        <hr />
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <div border="0" columns="3">
            <div
            style={{cusor:"pointer"}}
              className="btn btn-success"
              value="Save"
              onClick={handleSave}
            >
              Save
            </div>
            &nbsp;
            <div
            style={{cusor:"pointer"}}
              className="btn btn-danger"
              value="Delete"
              onClick={handleDelete}
            >
              Delete
            </div>
            &nbsp;
          </div>
        </div>
      </div>
    </form>
  );
};

export default TriggerDetail;
