import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link, useHistory } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import axios from "axios";
import { globalConfig } from "../config";
// import { SelectListComponent } from 'shared-components';
const querystring = require("querystring");


const RecipeStepCreate = () => {
  const data = useContext(AuthContext)
  const { addToast } = useToasts();
  const history = useHistory();
  const [statusCodes, setStatusCodes] = useState([]);
  const [lastId, setLastId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [lastUserId, setLastUserId] = useState(0);
  const [cid, setCid] = useState(0);
  const [priorityCodes, setPriorityCodes] = useState([]);
  const [recipeSteps, setRecipeSteps] = useState([]);
  const [transformations, setTransformations] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeStep,setRecipeStep] = useState({
    recipeStepId: 0,
      recipeId: 0,
      recipeSeqPos: 0,
      recipeStepName: "",
      securityContextId: 0,
      statusCode: 0,
      priorityCode: 0,
      instructions: "",
      recipeStepDescription: "",
      prevStepId: 0,
      nextStepId: 0,
      createUser: 0,
      createDate: new Date(),
      lastUpdateUser:0,
      lastUpdateDate: new Date(),
      transformerId: 0,
      triggerId: "",

  })
  const [state, setState] = useState({
    currentRecipeStepId: 0, // JSON.parse(parms).id ,
    currentRecipeId: 0, // JSON.parse(parms).rid,
    currentRecipeName: "",
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    //console.log("daadad");
    getLastRecipe();
  }, [cid]);

  const getLastRecipe = async () => {
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/recipe-steps?filter[where][recipeId]=${cid}/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const recipeSteps = res.data;
        //console.log(recipeSteps);
        setRecipeSteps(recipeSteps);
        //console.log("last", recipeSteps.at(-1).recipeSeqPos);
        // setLastId(recipeSteps[0].recipeSeqPos)
       // setState({...state,recipeStep:{...recipeStep,recipeSeqPos:recipeSteps.at(-1).recipeSeqPos+1}})
        setRecipeStep({...recipeStep,recipeSeqPos:recipeSteps.at(-1).recipeSeqPos+1})
      });
  };

  const handleOnLoad = async () => {
  
    await axios
    .get(
      `${globalConfig.apiMainUrl}/user-profile-service/userprofile`,
      {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
    )
    .then((res) => {
      const user = res.data;
     
      const idx = user.user.userId
      //console.log("user", idx);
     
       setUserId(idx)
       setLastUserId(idx)
     
     
    });

    await axios
      .get(
        `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/TASK_STATUS/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const statusCodes = res.data;
        //console.log("status", statusCodes);
        setStatusCodes(statusCodes);
      });

    await axios
      .get(
        `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/TASK_PRIORITY/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const priorityCodes = res.data;
        //console.log("status", priorityCodes);
        setPriorityCodes(priorityCodes);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const recipes = res.data;
        setRecipes(recipes);
      });

    //console.log("getting steps for recipeid - " + state.currentRecipeId);
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/recipe-steps?filter[where][recipeId]=${state.currentRecipeId}/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const recipeSteps = res.data;
        //console.log(recipeSteps);
        setRecipeSteps(recipeSteps);
        //console.log("last", recipeSteps.at(-1).recipeSeqPos);
        setLastId(recipeSteps.at(-1).recipeSeqPos);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const transformations = res.data;
        setTransformations(transformations);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/portal-recipes/${state.currentRecipeId}`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const recipe = res.data;
        //console.log(recipe.portalRecipName);
        setState({ currentRecipeName: recipe.portalRecipeName });
      });

    //console.log("getting step - " + state.currentRecipeStepId);
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/recipe-steps/${state.currentRecipeStepId}`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const recipeStep = res.data;
        //console.log(recipeStep);
        setRecipeStep(recipeStep)
      });

     
  };

  const handleSave = async () => {
    
    //console.log("handling the save event");
    

    //this.setState({recipeStep : {...recipeStep, transformerId : Number(recipeStep.transformerId)}});

    var idUrl = `${globalConfig.apiMainUrl}/next-id-generator/get-next/recipe-steps/recipeStepId`;

    recipeStep.transformerId = Number(recipeStep.transformerId);
    recipeStep.recipeId = Number(recipeStep.recipeId);
    recipeStep.recipeSeqPos = Number(recipeStep.recipeSeqPos);
    recipeStep.nextStepId = Number(recipeStep.nextStepId);
    recipeStep.prevStepId = Number(recipeStep.prevStepId);
    recipeStep.statusCode = Number(recipeStep.statusCode);
    recipeStep.priorityCode = Number(recipeStep.priorityCode);
    recipeStep.createUser = userId
    recipeStep.lastUpdateUser = lastUserId
    //console.log(recipeStep);


     await axios
      .get(idUrl, {headers:{
      Authorization: `Bearer ${data.token}`,
    }},)

      .then((response) => {
        recipeStep.recipeStepId = response.data["nextId"];

        //console.log("request object = ", JSON.stringify(recipeStep));
        axios
          .post(
            `${globalConfig.apiDatahubUrl}/recipe-steps/`,
            recipeStep,
            {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
          )
          .then((res) => {
            const results = res.data;
            //console.log(results);

            if (res.status === 200) {
              addToast("Recipe step created succesfully", {
                autoDismiss: true,
                appearance: "success",
              });
              history.push("/RecipeStepSearch");
            } else {
              addToast("An error occured", {
                autoDismiss: true,
                appearance: "error",
              });
              //console.log("An error occurred or transaction failed");
            }
          })
          .catch((err) => {
            addToast("An error occured", {
              autoDismiss: true,
              appearance: "error",
            });
            //console.log("An error occurred or transaction failed");
            //console.log("error - " + err);
          });
      }); 
  };

  const handleChange = (event) => {
    //this.setState({recipe : {...state.recipe, targetTypeCode : Number(state.recipe.targetTypeCode)}});

    var target = event.target;
    //console.log(target.id + ":" + target.value);
    setRecipeStep({
     ...recipeStep,
     [target.id]: target.value
    })
  
    //console.log(recipeStep);
  };

  return (
    <form>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>Create A Recipe Step</h4>
        <div
          style={{ cursor: "pointer", margin: "0px 5px" }}
          onClick={() => history.goBack()}
          className="btn btn-primary"
        >
          Go Back
        </div>
      </div>
      <hr />

      <div className="container-fluid">
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe">
            Recipe
          </label>
                 
          <select
            style={{ marginLeft: "-25px" }}
            className="form-control input-xlarge col-sm-8"
            id="recipeId"
            value={recipeStep.recipeId}
            id="recipeId"
            onChange={(e) => {
              handleChange(e);
              setCid(e.target.value);
            }}
          >
            <option key="" value="">
              Select Recipe
            </option>
            {recipes &&
              recipes.map((recipe) => (
                <>
                  <option
                    key={recipe.portalRecipeId}
                    value={recipe.portalRecipeId}
                  >
                    {recipe.portalRecipeName}
                  </option>
                </>
              ))}
                    
          </select>
          {/*
				<input onChange={handleChange} className="form-control input-xlarge col-sm-8" id="recipeName" value={state.currentRecipeName} />
		            	<select onChange={handleChange} className="form-control input-xlarge col-sm-8" id="recipeId" value={state.selectedRecipeId} id="recipeId" >
					{state.recipes.map((recipe) => <option key={recipe.portalRecipeId} value={recipe.portalRecipeId}>{recipe.portalRecipeName}</option>)}
			        </select>
				<select onChange={handleChange} id="recipeId" value={recipeStep.recipeId} className="form-control input-xlarge col-sm-8">
				<option value={ombRecipeStepForm.recipeSelectList} />
				</select>
			*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Seq Pos">
            Recipe Seq Pos
          </label>
          <input
            disabled={true}
            onChange={handleChange}
            className="form-control input-xlarge col-sm-8"
            id="recipeSeqPos"
            value={recipeStep.recipeSeqPos}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Name">
            Name{" "}
          </label>
          <input
            onChange={handleChange}
            className="form-control input-xlarge col-sm-8"
            id="recipeStepName"
            placeholder="Enter recipe Name"
            value={recipeStep.recipeStepName}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2">Status Code</label>
          <select
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
            id="statusCode"
            value={recipeStep.statusCode}
          >
            <option key="" value="">
              Select Status
            </option>
            {statusCodes &&
              statusCodes.map((status) => (
                <>
                  <option
                    key={status.systemCodeValueId}
                    value={status.systemCodeValueId}
                  >
                    {status.systemCodeValueValue}
                  </option>
                </>
              ))}
          </select>

          {/*
			<SelectListComponent IdName="statusCode" onChange={handleChange} entityName ={"system-code-values"}  onChange={handleChange}
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"90"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
			<select id="statusCode" value={recipeStep.statusCode} className="form-control input-xlarge col-sm-8">
			<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TASK_STATUS')} />
			</select>
	*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Priority Code">
            Priority Code
          </label>
          <select
            className="form-control input-xlarge col-sm-8"
            onChange={handleChange}
            id="priorityCode"
            value={recipeStep.priorityCode}
          >
            <option key="" value="">
              Select Priority
            </option>
            {priorityCodes &&
              priorityCodes.map((priority) => (
                <>
                  <option
                    key={priority.systemCodeValueId}
                    value={priority.systemCodeValueId}
                  >
                    {priority.systemCodeValueValue}
                  </option>
                </>
              ))}
          </select>
          {/*
			<SelectListComponent IdName="priorityCode" onChange={handleChange} entityName ={"system-code-values"} 
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"91"} displayColumnName ={"systemCodeValueValue"}  onChange={handleChange}
				keyColumnName ={"systemCodeValueId"} valueId={state.contentSourceType} />
			<select id="priorityCode" value={recipeStep.priorityCode} className="form-control input-xlarge col-sm-8">
			<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TASK_PRIORITY')} />
			</select>
	*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Instructions">
            Instructions
          </label>
          <input
            placeholder="Enter Instructions"
            onChange={handleChange}
            className="form-control input-xlarge col-sm-8"
            id="instructions"
            value={recipeStep.instructions}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Description">
            Description
          </label>
          <input
            placeholder="Description"
            onChange={handleChange}
            className="form-control input-xlarge col-sm-8"
            id="recipeStepDescription"
            value={recipeStep.recipeStepDescription}
          />
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Trigger">
            Trigger
          </label>
          <select
            onChange={handleChange}
            id="triggerId"
            value={recipeStep.triggerId}
            className="form-control input-xlarge col-sm-8"
          >
            <option key="" value="">
              Select Trigger
            </option>
            {transformations &&
              transformations.map((trigger) => (
                <>
                  <option
                    key={trigger.transformationId}
                    value={trigger.transformationId}
                  >
                    {trigger.transformationName}
                  </option>
                </>
              ))}
                    
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Transformer">
            {" "}
            Transformer
          </label>
          <select
            onChange={handleChange}
            id="transformerId"
            value={recipeStep.transformerId}
            className="form-control input-xlarge col-sm-8"
          >
            <option key="" value="">
              Select Transformation
            </option>
            {transformations &&
              transformations.map((transformation) => (
                <>
                  <option
                    key={transformation.transformationId}
                    value={transformation.transformationId}
                  >
                    {transformation.transformationName}
                  </option>
                </>
              ))}
          </select>
        </div>
        <div className="form-group row">
          <label value="Run Time" className="ContentTitle col-sm-2">
            Run Time
          </label>
          <input
            //onChange={handleChange}
            className="form-control input-xlarge col-sm-2"
            id="stepRunLatency"
            placeholder="Enter Run Time Here"
          />
          <select
            //onChange={handleChange}
            id="task_frequency"
            name="stepRunfrequency"
            className="form-control col-sm-6"
          >
            <>
              <option key="" value="">
                Select Task Frequency
              </option>
              <option value="immediately">Immediately</option>
              <option value="seconds">Seconds</option>
              <option value="hourly">Hours</option>
              <option value="daily">Days</option>
              <option value="weekly">Weeks</option>
              <option value="monthly">Months</option>
              <option value="quarterly">Quarters</option>
              <option value="annually">Years</option>
            </>
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Prev Step">
            {" "}
            Prev Step{" "}
          </label>
          <select
            onChange={handleChange}
            id="prevStepId"
            value={recipeStep.prevStepId}
            className="form-control input-xlarge col-sm-8"
          >
            <option key="" value="">
              Select Previous Step
            </option>
            {recipeSteps &&
              recipeSteps.map((recipeStep) => (
                <>
                  <option
                    key={recipeStep.recipeStepId}
                    value={recipeStep.recipeStepId}
                  >
                    {recipeStep.recipeStepName}
                  </option>
                </>
              ))}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Next Step">
            {" "}
            Next Step{" "}
          </label>
          <select
            onChange={handleChange}
            id="nextStepId"
            value={recipeStep.nextStepId}
            className="form-control input-xlarge col-sm-8"
          >
            <option key="" value="">
              Select Next Step
            </option>
            {recipeSteps &&
              recipeSteps.map((recipeStep) => (
                <>
                  <option
                    key={recipeStep.recipeStepId}
                    value={recipeStep.recipeStepId}
                  >
                    {recipeStep.recipeStepName}
                  </option>
                </>
              ))}
          </select>
        </div>
      </div>
      <hr />

      <div style={{display:"flex",justifyContent:"flex-end",paddingRight:"30px"}}className="form-group row">
        <label className="col-sm-2" />
      
        &nbsp;
        <div className="btn btn-success" value="Save" onClick={()=>{handleSave()}}>
          {" "}
          Create{" "}
        </div>
        &nbsp;
      </div>
    </form>
  );
};

export default RecipeStepCreate;
