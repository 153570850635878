import React, {Component} from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';
const querystring = require('querystring');

export default class CreateTransformer extends Component {

	constructor(props)
	{
		console.log(props);
    super(props);
  }

  state = {
  	transformerObject: {},
    transformers: []
  };

	componentDidMount() 
	{
		this.handleOnLoad();
	}

	handleOnLoad = () => {
      axios.get(`http://os-dev.origosoftware.com:3000/content-input-sources/`).then(res => {
      const connectors = res.data;
      console.log(connectors);
      this.setState({ connectors });
      console.log(res.data[0]);
		});
		
	}
	
	render(){
    return (

	<form>
		<div class="container-fluid">
		<h4>Create Transformer</h4>
		<hr />		

				<div class="form-group row">			
					<label class="ContentTitle col-sm-2" value="Transformer Name">Transformer Name</label>					
					<input class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.transformationName}"/>		        	
				</div>
				<div class="form-group row">			
					<label class="ContentTitle col-sm-2">Resource File Path</label>					
					<input class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.resourceFile }"/>		        	
				</div>
				<div class="form-group row">			
		        	<label class="ContentTitle col-sm-2">Class Name</label>					
					<input class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.className }"/>		        	
				</div>
				<div class="form-group row">			
		        	<label class="ContentTitle col-sm-2">Description</label>					
		        	<inputarea class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.description}" />
				</div>
				<div class="form-group row">			
			        <label class="ContentTitle col-sm-2">Version</label>
		        	<inputarea class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.transformationVersion}" />
				</div>
				<div class="form-group row">			
			        <label class="ContentTitle col-sm-2">Status</label>
			        
		        		<select partialSubmit="true" class="form-control input-xlarge col-sm-8" id="indexCode" value="#{ombTransformationForm.createTransformation.statusCode}" >
							<option value="#{ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('TRANSFORMATION_STATUS')}" />
						</select>
					
				</div>
				<div class="form-group row">			
			        <label class="ContentTitle col-sm-2">Parameters</label>
		        		<textArea class="form-control input-xlarge col-sm-8" value="#{ombTransformationForm.createTransformation.parameters}" />
				</div>

				<div class="form-group row">
						<label class="ContentTitle col-sm-2">Upload Transformer</label>		
						<input type="file" id="fileUpload"/>
    	 				<button type="submit" value="Upload" class="btn btn-info" id="uploadActor" actionListener="#{ombUserProjectsForm.fileUploadActionListener }">
    	 					Upload
    	 				</button>
				</div>

		<hr/>
			<center>
			<div class="form-group row">			
				<button class="btn btn-success" value="Create"  actionListener="#{ombTransformationForm.create}" >Create</button>&nbsp; 
				<button class="btn btn-primary" value="Clear" actionListener="#{ombTransformatioForm.clear}" immediate="true">Clear</button> 
			</div>
			</center>
 		
	</div>
	</form>
);
}
}
