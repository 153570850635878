//Pagination
import React, { useState, useMemo } from "react";
import { globalConfig } from "../config";

const Pagination = ({ dataPerPage, totaldata, paginate,currentPage,onChange }) => {
  

  const orderInFive = useMemo(() => {
    if (currentPage % 5 === 0) {
      return parseInt(currentPage / 5) - 1;
    } else {
      return parseInt(currentPage / 5);
    }
  }, [currentPage]);
  const int = Math.ceil(totaldata / dataPerPage);

  return (
    <nav>
      <ul className="pagination justify-content-center">
        {currentPage - 1 > 0 && (
          <a
            onClick={() => {
              onChange(currentPage - 1);
              paginate(currentPage - 1);
            }}
            href="#"
            className="page-link"
          >
            Prev
          </a>
        )}
        {new Array(int - orderInFive * 5 >= 5 ? 5 : int - orderInFive * 5)
          .fill(0)
          .map((_, index) => (
            <li
              key={index}
              className={`page-item ${
                currentPage === orderInFive * 5 + index + 1 ? "active" : ""
              }`}
            >
              <a
                onClick={() => {
                  onChange(orderInFive * 5 + index + 1);
                  paginate(orderInFive * 5 + index + 1);
                }}
                href="#"
                className="page-link"
              >
                {orderInFive * 5 + index + 1}
              </a>
            </li>
          ))}

        {int - currentPage >= 1 && (
          <a
            onClick={() => {
              onChange(currentPage + 1);
              paginate(currentPage + 1);
            }}
            className="page-link"
            href="#"
          >
            Next
          </a>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;