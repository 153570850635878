import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import logo from './logo.svg';
// import './App.css';
import HomePage from "./components/HomePage";
import ManageProject from "./components/ManageProject";
import ManageRecipes from "./components/ManageRecipes";
import CreateRecipe from "./components/CreateRecipe";
import RecipeDetail from "./components/RecipeDetail";
import RecipeSearch from "./components/RecipeSearch";
import RecipeStepSearch from "./components/RecipeStepSearch";
import RecipeStepDetail from "./components/RecipeStepDetail";
import RecipeStepCreate from "./components/RecipeStepCreate";
import Triggers from "./components/Triggers";
import TriggerDetail from "./components/TriggerDetail"
import TriggerCreate from"./components/TriggerCreate";
import LayoutDetails from "./components/LayoutDetails";
import BuildDetails from "./components/BuildDetails";
import DeployDetails from "./components/DeployDetails";
import ProjectRecipes from "./components/ProjectRecipes";
import Projects from "./components/Projects";
import Transformers from "./components/Transformers";
import ManageTransformer from "./components/ManageTransformer"
import CreateTransformer from "./components/CreateTransformer";
import { ToastProvider } from 'react-toast-notifications'
import { useAuth0 } from "@auth0/auth0-react";
import { Auth } from "./components/Auth/Auth";
import { Loading } from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";



function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

	if (!isLoading && !isAuthenticated)
	{
		logout();
	}
  console.log(isAuthenticated, isLoading);
  return (
    <>
    {isLoading ? (
      <Loading />
    ) : !isAuthenticated ? (
      loginWithRedirect()
    ) : (
      <Auth>
          <ToastProvider>
            <ErrorBoundary>
    <Router>
    
    <div className="App">
      <header className="App-header">
          <Route path="/" exact component={HomePage} />
          <Route path = "/ManageProject/" component={ManageProject}/>
          <Route path = "/ManageRecipes/" component={ManageRecipes}/>
          <Route path = "/CreateRecipe/" component={CreateRecipe}/>
          <Route path = "/RecipeDetail/:id" component={RecipeDetail}/>
          <Route path = "/RecipeSearch/" component={RecipeSearch}/>
          <Route path = "/RecipeStepSearch/" component={RecipeStepSearch}/>
          <Route path = "/RecipeStepDetail/:id/:rid" component={RecipeStepDetail}/>
          <Route path ="/RecipeStepCreate" component = {RecipeStepCreate}/>
          <Route path ="/Triggers" component = {Triggers}/>
          <Route path ="/TriggerDetail/:id" component = {TriggerDetail}/>
          <Route path ="/TriggerCreate" component = {TriggerCreate}/>
          <Route path ="/LayoutDetails/:id" component = {LayoutDetails}/>
          <Route path ="/BuildDetails/:id" component = {BuildDetails}/>
          <Route path ="/DeployDetails/:id" component = {DeployDetails}/>
          <Route path="/ProjectRecipes" component={ProjectRecipes} />
          <Route path="/Projects" component={Projects} />
          <Route path="/transformers" component={Transformers} />
          <Route path="/manage-transformer" component={ManageTransformer} />
          <Route path="/create-transformer" component={CreateTransformer} />
      </header> 
    </div>
  
    </Router>
    </ErrorBoundary>
          </ToastProvider>
        </Auth>
    )}
    </>
  );
}

export default App;