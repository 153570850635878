import React, { useState, useEffect ,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link,useHistory,useLocation } from "react-router-dom";
import axios from "axios";
// import { SelectListComponent } from 'shared-components';
import { globalConfig } from "../config";
import { getSingleUser, updateSingleUser } from '../services/user'
const querystring = require("querystring");

const RecipeCreate = () => {
  const data = useContext(AuthContext)
  const history = useHistory();
  const location = useLocation();
 const [projects,setProjects]=useState([])
  
  const [state, setState] = useState({
   
    desktops: [],
    targetTypes: [],
    buildStatusCodes: [],
  });
  const [transformations, setTransformations] = useState([]);
  const [targetTypes, setTargetTypes] = useState([]);
  const [buildStatusCodes, setBuildStatusCodes] = useState([]);
  const [desktops, setDesktops] = useState([]);
  const [message, setMessage] = useState("");
  const [recipe, setRecipe] = useState({
    portalRecipeId: 0,
    portalRecipeName: "",
    portalRecipeDescription: "",
    securityContextId: 0,
    createUser: 0,
    createDate: new Date(),
    lastUpdateUser: 0,
    lastUpdateDate: new Date(),
    userProjectId: 0,
    userWorkspaceId: 0,
    targetTypeCode: 0,
    portalAccessServer: "",
    portalAccessPath: "",
    portalAccessProtocol: "",
    portalAccessUsername: "",
    portalAccessPassword: "",
    buildStatusCode: 0,
    transformationId: 0,
  });

 

  useEffect(() => {
    //alert(JSON.stringify(location.state.userData));
    handleOnLoad();
    handleOnLoad2();
  }, []);

  const handleOnLoad = async () => {
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/transformations/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const transformationsx = res.data;
        setTransformations(transformationsx);
      });

    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/user-workspaces/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const desktop = res.data;
        //console.log(desktop);
        setDesktops(desktop);
      });
      await axios
      .get(
        `${globalConfig.apiMainUrl}/user-profile-service/userprofile`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const user = res.data;
        //console.log("user", user);
        setRecipe({...recipe, createUser:user.user.userId,lastUpdateUser:user.user.userId});
        
      });
  };
  const handleOnLoad2 = async () => {
  
    axios
      .get(
       /*  `${globalConfig.apiDatahubUrl}/user-projects?&filter[where][or][0][ownerUserId][regexp]=/(${x})/i`, */
       `${globalConfig.apiDatahubUrl}/user-projects-by-id/`, 
       {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const userProjects = res.data;
        //filter by project createUserId
        //console.log("projs",userProjects)
       setProjects(userProjects)
      });
    //console.log("sec");

    const { data: dataAppTypes } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/APPLICATION_TYPES`,
      {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
    );

    setTargetTypes(dataAppTypes);

    const { data: dataBuildStatusCodes } = await axios.get(
      `${globalConfig.apiMainUrl}/code-catalog-data-service/get-system-code-values/BUILD_STATUS`,
      {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
    );

    setBuildStatusCodes(dataBuildStatusCodes);
  };
  const handleClear = () => {
    setRecipe({
      portalRecipeId: 0,
      portalRecipeName: "",
      portalRecipeDescription: "",
      securityContextId: 0,
      createUser: 0,
      createDate: new Date(),
      lastUpdateUser: 0,
      lastUpdateDate: new Date(),
      userProjectId: 0,
      userWorkspaceId: 0,
      targetTypeCode: 0,
      portalAccessServer: "",
      portalAccessPath: "",
      portalAccessProtocol: "",
      portalAccessUsername: "",
      portalAccessPassword: "",
      buildStatusCode: 0,
      transformationId: 0,
    });
  };

  const handleSave = () => {
    setRecipe({ ...recipe, targetTypeCode: parseInt(recipe.targetTypeCode) });
    setRecipe({ ...recipe, buildStatusCode: parseInt(recipe.buildStatusCode) });
    setRecipe({
      ...recipe,
      transformationId: parseInt(recipe.transformationId),
    });
    setRecipe({ ...recipe, userProjectId: parseInt(recipe.userProjectId) });
    setRecipe({ ...recipe, userWorkspaceId: parseInt(recipe.userWorkspaceId) });

    var url = `${globalConfig.apiDatahubUrl}/portal-recipes/`;
    var idUrl = `${globalConfig.apiMainUrl}/next-id-generator/get-next/portal-recipes/portalRecipeId`;

    axios.get(idUrl, {headers:{
      Authorization: `Bearer ${data.token}`,
    }},).then((response) => {
      recipe.portalRecipeId = response.data["nextId"];

      //console.log("request object = " + JSON.stringify(recipe));
      axios
        .post(url, recipe, {headers:{
      Authorization: `Bearer ${data.token}`,
    }},)
        .then((response2) => {
          //console.log("response.data");
          if (response2.status === 200) {
            setMessage("Success");
          } else {
            setMessage("Failed");
          }
          //console.log(message);
          //
        })
        .catch((err) => {
          //console.log("error - " + err);
        });
      //console.log("message info - " + message);

      history.push("/ProjectRecipes")
    });
  };

  return (
    <form>
      <div class="container-fluid">
        <div style={{display:"flex",justifyContent:"space-between"}}>
		<h4>Create Recipe</h4>
		<div style={{cursor:"pointer"}} onClick={()=>history.goBack()} className="btn btn-primary">Go Back</div>
		</div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Name">
            Recipe Name
          </label>
          <input
            className="form-control input-medium col-sm-8"
            id="portalRecipeName"
            value={recipe.portalRecipeName}
            onChange={(e) =>
              setRecipe({ ...recipe, portalRecipeName: e.target.value })
            }
          ></input>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Recipe Description">
            Recipe Description
          </label>
          <textArea
            className="form-control input-big col-sm-8"
            id="portalRecipeDescription"
            rows="4"
            cols="65"
            value={recipe.portalRecipeDescription}
            onChange={(e) =>
              setRecipe({ ...recipe, portalRecipeDescription: e.target.value })
            }
          ></textArea>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Project">
            Project
          </label>
          <select
            id="userProjectId"
            className="form-control input-big col-sm-8"
            value={recipe.userProjectId}
            onChange={(e) =>
              setRecipe({ ...recipe, userProjectId: parseInt(e.target.value) })
            }
          >
            <option>Select Project</option>
            {projects &&
              projects?.map((project) => (
                <option
                  key={project.userProjectId}
                  value={project.userProjectId}
                >
                  {project.projectName}
                </option>
              ))}
            {/*<option value={ombUserProjectsForm.getUserProjectList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Desktop">
            Desktop
          </label>
          <select
            id="userDesktopId"
            className="form-control input-big col-sm-8"
            value={recipe.userWorkspaceId}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                userWorkspaceId: parseInt(e.target.value),
              })
            }
          >
            <option>Select Desktop</option>
            {desktops &&
              desktops.map((desktop) => (
                <option
                  key={desktop.userWorkspaceId}
                  value={desktop.userWorkspaceId}
                >
                  {desktop.workspaceName}
                </option>
              ))}
            {/*<option value={ombUserDesktopsForm.getUserDesktopList(ombUserSession.getCurrentUserId())}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Target Type">
            Target Type
          </label>
          <select
            id="targetTypeId"
            className="form-control input-big col-sm-8"
            value={recipe.buildStatusCode}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                buildStatusCode: parseInt(e.target.value),
              })
            }
          >
            <option>Select Target</option>
            {targetTypes &&
              targetTypes.map((targetType) => (
                <option
                  key={targetType.systemCodeValueId}
                  value={targetType.systemCodeValueId}
                >
                  {targetType.systemCodeValueName}
                </option>
              ))}
          </select>
          {/*
				<SelectListComponent IdName="targetTypeCode" onChange={(e)=>setRecipe({...recipe,portalRecipeName:e.target.value})} entityName ={"system-code-values"} 
				valueFilterColumnName = {"systemCodeId"} valueFilter = {"84"} displayColumnName ={"systemCodeValueValue"} 
				keyColumnName ={"systemCodeValueId"}/>
           			<select id="targetTypeCode" className="form-control input-big col-sm-8" value={recipe.targetTypeCode } onChange={(e)=>setRecipe({...recipe,portalRecipeName:e.target.value})}>
				<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('APPLICATION_TYPES')}/>
  				</select>		
				*/}
        </div>
        <div className="form-group row">
          <label className="ContentTitle col-sm-2" value="Transformation">
            Transformation
          </label>
          <select
            id="transformationId"
            className="form-control input-big col-sm-8"
            value={recipe.transformationId}
            onChange={(e) =>
              setRecipe({
                ...recipe,
                transformationId: parseInt(e.target.value),
              })
            }
          >
            <option>Select Transformation</option>
            {transformations &&
              transformations.map((transformation) => (
                <option
                  key={transformation.transformationId}
                  value={transformation.transformationId}
                >
                  {transformation.transformationName}
                </option>
              ))}
            {/*<option value={ombTransformationForm.transformations}/>*/}
          </select>
        </div>
        <div className="form-group row">
          <label
            className="ContentTitle col-sm-2"
            value="Build Status (Latest)"
          >
            Build Status (Latest)
          </label>
          <select
            id="targetTypeId"
            className="form-control input-big col-sm-8"
            value={recipe.buildStatusCode}
            onChange={(e) =>
              setRecipe({ ...recipe, buildStatusCode: parseInt(e.target.value) })
            }
          >
            <option>Select Build Status</option>
            {buildStatusCodes &&
              buildStatusCodes.map((build) => (
                <option
                  key={build.systemCodeValueId}
                  value={build.systemCodeValueId}
                >
                  {build.systemCodeValueName}
                </option>
              ))}
          </select>
          {/*
				<SelectListComponent IdName="buildStatusCode" onChange={(e)=>setRecipe({...recipe,portalRecipeName:e.target.value})} entityName ={"system-code-values"} 
					valueFilterColumnName = {"systemCodeId"} valueFilter = {"94"} displayColumnName ={"systemCodeValueValue"} 
					keyColumnName ={"systemCodeValueId"}/>
	           		<select id="buildStatusCode" className="form-control input-big col-sm-8" value={recipe.buildStatusCode } onChange={(e)=>setRecipe({...recipe,buildStatusCode:e.target.value})}>
				<option value={ombSystemLookupCodesControl.getSystemCodeValuesSelectBox('BUILD_STATUS')}/>
  				</select>
				*/}
        </div>

        <hr />
        <center>
          <div style={{display:"flex",justifyContent:"flex-end",paddingRight:"10px"}} className="form-group row">
            &nbsp;&nbsp;&nbsp;
          
            &nbsp;
            <div style={{cursor:"pointer"}} className="btn btn-success" value="Save" onClick={handleSave}>
              Save
            </div>
          </div>
        </center>
      </div>
    </form>
  );
};
export default RecipeCreate;
