import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from "./App";
import history from "./utils/history";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { globalConfig } from "./config";

// import 'bootstrap/dist/css/bootstrap.css';
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={globalConfig.auth0.domain}
    clientId={globalConfig.auth0.clientId}
    audience={`https://${globalConfig.auth0.domain}/api/v2/`}
    scope="read:current_user read:current_user_metadata"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
