import React, { useState,useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
import TableLoader from '../Skeleton/TableLoader'
const querystring = require("querystring");



 const Triggers =()=> {
  const data = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true);
   const [currentPage, setCurrentPage] = useState(1);
   const [message, setMessage] = useState("");
   const [search, setSearch] = useState("");
	const [state, setState] = useState({
		triggers: [],
		
	  });
	 useEffect(()=>{
		 handleOnLoad();
   },[])

   useEffect(()=>{
    handleSearch(search);
  },[search]) 
 
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData = state.triggers.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const handleSearch = (search) => {
    setIsLoading(true)
    let URL = `${globalConfig.apiMainUrl}/event-triggers?`;
   
    URL += `&search=${search}`;
    axios.get(URL, globalConfig.hardcoded_header).then((res) => {
      const triggers = res.data;
      console.log("trigs",triggers);
      setState({
        ...state,
        triggers: triggers,
      });
      console.log(res.data[0]);
      setCurrentPage(1)
    });
  };
 

  const handleOnLoad = async() => {
    console.log("dsdsd")
    
   await axios
      .get(
   
        `${globalConfig.apiMainUrl}/event-triggers/`,globalConfig.hardcoded_header
      )
      .then((res) => {
        const triggers = res.data;
    setState({ triggers:triggers });
    
      /*   axios.get(`http://os-dev.origosoftware.com:5100/mongo-service/gazelle/EventTriggers/`).then(res => {
		
        console.log('trigs',triggers);
      
        //console.log(res.data[0]); */
      });
  };

  const handleDelete = async(id) => {
    const url = `${globalConfig.apiMainUrl}/event-triggers/${id}`;
    await axios
    .delete(
      
      url,
      {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
    )
    .then((response2) => {
      console.log("response.data");
      if (response2.status === 200) {
        setMessage("Success");
        window.location.reload();
      } else {
        setMessage("Failed");
      }
      console.log(message);
      //
    })
    .catch((err) => {
      console.log("error - " + err);
    });
  };


  

    return (
      <form>
        <div className="container-fluid">
          <div className="text-right">
            <Link
              to="/TriggerCreate"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="btn btn-success"
            >
              Create Trigger
            </Link>
          </div>

          <div className="form-group">
          <input
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
          <div className="form-group row">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Id</th>
                  <th scope="col">Number</th>
                  <th scope="col">Event Trigger Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map(
                  ({
                    _id,
                    eventTriggerNumber,
                    eventTriggerName,
                    description,
                  }) => {
                    return (
                      <tr>
                        <td>
                          <Link
                            to={`/TriggerDetail/${_id}`}
                          >
                            {_id}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/TriggerDetail/${_id}`}
                          >
                            {eventTriggerNumber}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/TriggerDetail/${_id}`}
                          >
                            {eventTriggerName}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/TriggerDetail/${_id}`}
                          >
                            {" "}
                            {description}
                          </Link>
                        </td>
                        <td>
                          <div style={{cursor:"pointer"}} onClick={()=>handleDelete(_id)} className="btn btn-danger">Delete</div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={state.triggers.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
      </form>
    );
  }
  


export default Triggers;
