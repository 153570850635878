import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from './Auth/Auth'
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import { globalConfig } from "../config";
import Pagination from "../utils/Paginate";
const querystring = require("querystring");

const Projects = () => {
  const data = useContext(AuthContext)
    const history = useHistory();
    const [userData,setUserData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
  const [state, setState] = useState({
    projects: [],
  });

  
  
  useEffect(() => {
    
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //handler for page
  const handlePage = (page) => {
    setCurrentPage(page);
  };
  const indexOfLastData = currentPage * globalConfig.dataPerPage;
  const indexOfFirstData = indexOfLastData - globalConfig.dataPerPage;
  const currentData =state.projects.slice(
    indexOfFirstData,
    indexOfLastData
  ) 

  const handleOnLoad = async() => {
    await axios
      .get(
        `${globalConfig.apiDatahubUrl}/user-projects-by-id/`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const projects = res.data;
        setState({ projects });
        console.log("project is",projects)
      });
  };

  const handleSearch = (search) => {
    let URL = `${globalConfig.apiDatahubUrl}/user-projects-by-id?`;
    URL += `&filter[where][or][0][projectName][regexp]=/(${search})/i`;
    axios.get(URL, {headers:{
      Authorization: `Bearer ${data.token}`,
    }},).then((res) => {
      const projects = res.data;
      console.log(projects);
      setState({
        ...state,
        projects: projects,
      });
      console.log(res.data[0]);
      setCurrentPage(1)
    });
  };

  const deleteRecipe = (id) => {
    axios
      .delete(
        `${globalConfig.apiDatahubUrl}/user-projects/${id}`,
        {headers:{
      Authorization: `Bearer ${data.token}`,
    }},
      )
      .then((res) => {
        const projects = res.data;
      });
  };

  return (
    <form>
      <div class="container-fluid">
          <div className="text-right">
          <div
              onClick={() => history.push ({pathname:"/CreateRecipe" , state:{userData:userData}}) }
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="btn btn-success"
            >
              Create Project
            </div>
          </div>
     
      <div className="form-group">
          <input
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="exampleFormControlInput1"
            placeholder="Search"
          />
        </div>
        <div className="form-group row">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Project Name</th>
                {/*<th scope="col">Build Status</th>*/}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(
                ({ userProjectId, projectName, buildStatusCode }) => {
                  return (
                    <tr>
                      <td>
                        <Link
                          to={{ pathname: `/RecipeDetail/${userProjectId}` }}
                        >
                          {userProjectId}
                        </Link>
                      </td>
                      <td>
                      <Link
                          to={{ pathname: `/RecipeDetail/${userProjectId}` }}
                        >
                          {projectName}
                        </Link>
                      </td>
                      {/*<td><Link  to={{ pathname: "/RecipeDetail", search : `?id=${userProjectId}` }}>{buildStatusCode}</Link></td>*/}
                      <td>
                        {/*<button class="btn btn-success">Edit</button>&nbsp;*/}
                        <div
                          class="btn btn-danger"
                          onClick={() =>
                            deleteRecipe(userProjectId)
                          }
                        >
                          Delete
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        dataPerPage={globalConfig.dataPerPage}
        totaldata={state.projects.length}
        paginate={paginate}
        currentPage={currentPage}
        onChange={handlePage}
      />
    </form>
     
  );
};

export default Projects;
